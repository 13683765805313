import { Button, Grid, Slider, Typography } from "@mui/material";
import { grey, orange, green } from "@mui/material/colors";
import { Box, Container, useTheme } from "@mui/system";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  registerables as registerablesJS,
  Title,
  Tooltip,
} from "chart.js";
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { Chart } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { DepartmentsContext, UserContext } from "../../components/PrivateRoute";
import { ALUMNI, STAFF } from "../../data/Roles";
import { tenantId, XeroAPI } from "../../data/XeroAPI";
import { currentYear, formatDateDash } from "../../helpers/FormatDate";
import { Path } from "../../helpers/Path";
import { Staff } from "../../models/Staff";
import {
  get,
  getPledgeGoals,
  getToken,
  InitPledgeGoals,
  isXeroAuthenticated,
  SetAuthenticated,
} from "../../services/xero";
import {
  convertProfitAndLosses,
  monthsSinceXeroStartYear,
  XeroInterface,
  xeroStartYear,
  XeroTimeFrame,
} from "./PledgesHelper";

export const Pledges = () => {
  const [state, setState] = useState<XeroInterface>({
    loading: true,
    slider: [0, 1],
    pledgesGoals: InitPledgeGoals,
  });

  const departments = useContext(DepartmentsContext);
  const user = useContext(UserContext);

  const { loading, profitAndLossPledges, pledgesGoals, slider } = state;

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });
    if (!isXeroAuthenticated()) {
      getToken(
        "openid profile email accounting.transactions.read accounting.settings.read accounting.contacts.read accounting.reports.read offline_access"
      ).then((token) => {
        SetAuthenticated(token);
        getInitialProfitAndLoss();
      });
    } else {
      getInitialProfitAndLoss();
    }
    // eslint-disable-next-line
  }, []);

  const getInitialProfitAndLoss = () =>
    Promise.all(
      Array.from({ length: currentYear - xeroStartYear + 1 }, (_, i) => i + xeroStartYear).map((year) =>
        get(XeroAPI.ProfitAndLoss, tenantId, {
          fromDate: formatDateDash(new Date(year as number, 11, 1)),
          toDate: formatDateDash(new Date(year as number, 11, 31)),
          periods: 11,
          timeframe: XeroTimeFrame.MONTH,
        })
      )
    )
      .then((profitAndLosses) => {
        getPledgeGoals().then((pledgesGoals) =>
          setState({
            ...state,
            loading: false,
            profitAndLossPledges: convertProfitAndLosses(profitAndLosses),
            pledgesGoals,
            slider: [monthsSinceXeroStartYear - 11, monthsSinceXeroStartYear],
          })
        );
      })
      .catch((e) => console.log(e));

  const theme = useTheme();

  const slicedPNL = {
    periods: profitAndLossPledges?.periods.slice(slider[0], slider[1] + 1),
  };

  ChartJS.register(...registerablesJS);
  ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

  const options = (text: string) => ({
    scales: {
      y: {
        min: 0,
      },
    },
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    spanGaps: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: text,
      },
    },
  });

  const staffOptions = options("Staff Pledges (old)");
  const pledgesOptions = options("Pledges");
  const cumulativePledgesOptions = (year: number) =>
    options("Cumulative Pledges " + year.toString() + " - " + (year + 1).toString());

  const labels = slicedPNL.periods?.map((period) => period.date);

  const getGoalList = (goals: { [date: string]: number }) => {
    let goalList: number[] = [];
    let initValue = goals["Jan 20"];
    let prevValue = initValue;
    if (profitAndLossPledges?.periods) {
      for (const period of profitAndLossPledges?.periods) {
        if (goals[period.date]) {
          goalList.push(goals[period.date]);
          prevValue = goals[period.date];
        } else {
          goalList.push(prevValue);
        }
      }
    }
    return goalList;
  };

  const getFinancialYearGoalList = (year: number) => {
    let labels: string[] = [];
    let goalList: number[] = [];
    let dataList: number[] = [];
    let initValue = pledgesGoals.cumulativeGoals?.["Jul " + year.toString().slice(-2)] ?? 500;
    let currGoal = 0;
    let currData = 0;
    if (profitAndLossPledges?.periods) {
      if (year === financialYear) {
        const index = profitAndLossPledges.periods.findIndex(
          (p) => p.date === "Jul " + financialYear.toString().slice(-2)
        );
        for (const period of profitAndLossPledges.periods.slice(index)) {
          labels.push(period.date);
          currData += period.alumniPledge + period.staffPledge;
          currGoal += initValue;
          goalList.push(currGoal);
          dataList.push(currData);
        }
      } else {
        const index = profitAndLossPledges.periods.findIndex((p) => p.date === "Jul " + year.toString().slice(-2));
        const endIndex = profitAndLossPledges.periods.findIndex(
          (p) => p.date === "Jul " + (year + 1).toString().slice(-2)
        );
        for (const period of profitAndLossPledges.periods.slice(index, endIndex)) {
          labels.push(period.date);
          currData += period.alumniPledge + period.staffPledge;
          currGoal += initValue;
          goalList.push(currGoal);
          dataList.push(currData);
        }
      }
    }
    return { labels, goalList, dataList };
  };

  const data = (
    pledgeGroup: string,
    goals: { [date: string]: number },
    data: number[] | undefined,
    goalColor: string,
    color: string
  ) => ({
    labels,
    datasets: [
      {
        type: "line" as const,
        label: `${pledgeGroup} goal`,
        data: getGoalList(goals).slice(slider[0], slider[1] + 1),
        borderColor: goalColor,
        backgroundColor: goalColor,
      },
      {
        type: "bar" as const,
        label: pledgeGroup,
        data: data,
        borderColor: color,
        backgroundColor: color,
      },
    ],
  });

  const today = new Date();
  const financialYear = today < new Date(today.getFullYear(), 7, 1) ? today.getFullYear() - 1 : today.getFullYear();

  const cumulativeDataFn = (pledgeGroup: string, goalColor: string, color: string) => {
    const graphs = [];
    for (let i = 2020; i <= financialYear; ++i) {
      const { labels, goalList, dataList } = getFinancialYearGoalList(i);
      graphs.push({
        labels,
        datasets: [
          {
            type: "line" as const,
            label: `${pledgeGroup} goal`,
            data: goalList,
            borderColor: goalColor,
            backgroundColor: goalColor,
          },
          {
            type: "bar" as const,
            label: pledgeGroup,
            data: dataList,
            borderColor: color,
            backgroundColor: color,
          },
        ],
      });
    }

    return graphs;
  };

  const staffData = data(
    STAFF,
    pledgesGoals.staffGoals,
    slicedPNL.periods?.map((period) => period.staffPledge),
    grey[700],
    grey[400]
  );

  const alumniData = data(
    ALUMNI,
    pledgesGoals.alumniGoals,
    slicedPNL.periods?.map((period) => period.alumniPledge),
    orange[700],
    orange[400]
  );

  const cumulativeData = cumulativeDataFn("Pledges", green[700], green[400]);

  const setValue1 = (value: number[]) => setState({ ...state, slider: value });

  const minDistance = 1;

  const handleChange1 = (
    event: Event | SyntheticEvent<Element, Event>,
    value: number | number[],
    activeThumb: number
  ) => {
    if (typeof value === "number") {
      return;
    }
    if (value[0] === slider[0] && value[1] === slider[1]) {
      return;
    }
    if (!Array.isArray(value)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(value[0], slider[1] - minDistance), slider[1]]);
    } else {
      setValue1([slider[0], Math.max(value[1], slider[0] + minDistance)]);
    }
  };

  const marks =
    profitAndLossPledges &&
    profitAndLossPledges.periods.map((period, i) => ({
      value: i,
      label: period.date,
    }));

  const formatLabels = marks?.map((mark) => ({
    ...mark,
    label: mark.label.startsWith("Jan") ? "20" + mark.label.slice(-2) : "",
  }));

  const history = useHistory();

  return loading ? (
    <Loading />
  ) : (
    <Container maxWidth="lg">
      {(user as Staff).department === departments.FINANCE && (
        <Grid container justifyContent="flex-end">
          <Grid container justifyContent="flex-end">
            <Button onClick={() => history.push(Path["Set Budget Manager"])} variant="contained" size="small">
              Set budget manager for this/next year
            </Button>
          </Grid>
          <Box pb={7}>
            <Button onClick={() => history.push(Path["Edit Pledges Goals"])} variant="contained" size="medium">
              Edit Pledges Goals
            </Button>
          </Box>
        </Grid>
      )}
      {cumulativeData
        .map((d, i) => <Chart type="bar" options={cumulativePledgesOptions(i + 2020)} data={d} />)
        .reverse()}
      {profitAndLossPledges && (
        <Slider
          getAriaLabel={() => "Minimum distance"}
          value={slider}
          onChange={handleChange1}
          min={0}
          max={profitAndLossPledges.periods.length - 1}
          valueLabelDisplay="on"
          valueLabelFormat={(value) => <Typography>{marks ? marks[value].label : 0}</Typography>}
          marks={formatLabels}
          disableSwap
        />
      )}
      <Chart type="bar" options={pledgesOptions} data={alumniData} />
      <Box sx={{ mt: theme.spacing(6) }} />
      <Chart type="bar" options={staffOptions} data={staffData} />
    </Container>
  );
};
