import { Link as ReactRouterLink } from "react-router-dom";
import { Box, Button, Grid, Link, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Path } from "../../helpers/Path";
import { User } from "../../models/User";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { ColoursCampuses } from "../../data/colours";
import { atLeast } from "../../helpers/Role";
import { universities, University } from "../../data/University";
import { TypographyBold } from "../../components/TypographyBold";
import { grey } from "@mui/material/colors";
import { STAFF } from "../../data/Roles";
import { currentStaffYear } from "../../helpers/FormatDate";

export const OrganisationChartNode = ({
  roles,
  user,
  text,
  show,
  toggle,
  year,
}: {
  roles: string[];
  user?: User;
  text?: string;
  show?: boolean;
  toggle?: () => void;
  year: number;
}) => {
  const theme = useTheme();

  const colour = atLeast(roles, STAFF, user?.role)
    ? ColoursCampuses.STAFF
    : Object.values(ColoursCampuses)[universities.indexOf(user?.university as University) + 1];

  const getFirstName = (email?: string) =>
    email?.match("@sowaustralia.com$")
      ? email.split(".")[0].charAt(0).toUpperCase() + email.split(".")[0].slice(1)
      : "";
  const getLastName = (email?: string) =>
    email?.match("@sowaustralia.com$")
      ? email.split(".")[1].split("@")[0].charAt(0).toUpperCase() + email.split(".")[1].split("@")[0].slice(1)
      : "";
  return (
    <>
      {user && (
        <Link
          sx={{
            display: "inline-block",
            margin: theme.spacing(0, 0, -0.83, 0),
          }}
          component={ReactRouterLink}
          underline="hover"
          to={year === currentStaffYear ? `${Path.Profile}/${user.id}` : "#"}
        >
          <Paper
            elevation={3}
            sx={{
              position: "relative",
              margin: theme.spacing(0, 0.75, 0, 0.75),
              width: theme.spacing(17),
              height: theme.spacing(8),
              padding: theme.spacing(1, 2, 0, 2),
              borderRadius: "20px",
            }}
          >
            {user.photoURL ? (
              <Box
                component="img"
                alt=""
                src={user.photoURL}
                sx={{
                  position: "absolute",
                  left: "-12px",
                  top: "-12px",
                  width: theme.spacing(5.25),
                  height: theme.spacing(5.25),
                  outlineOffset: "-1px",
                  overflow: "hidden",
                  objectFit: "cover",
                  borderRadius: "42px",
                  outline: "4px solid " + colour,
                }}
              />
            ) : (
              <PersonIcon
                style={{ fontSize: theme.spacing(5.25), color: "grey" }}
                sx={{
                  position: "absolute",
                  left: "-12px",
                  top: "-12px",
                  width: theme.spacing(5.25),
                  height: theme.spacing(5.25),
                  borderRadius: "50%",
                  outlineOffset: "-1px",
                  outline: "4px solid " + colour,
                  backgroundColor: colour,
                }}
              />
            )}
            <Grid
              container
              sx={{
                position: "absolute",
                bottom: "8px",
                padding: theme.spacing(0, 4, 0, 0),
              }}
              justifyContent="flex-end"
            >
              {text && (
                <Grid item xs={12}>
                  <TypographyBold sx={{ height: "12px", fontSize: "10.5px" }} align="right">
                    {text === "Training and Development"
                      ? "Training & Dev."
                      : text === "Human Resources"
                      ? "HR"
                      : text === "People and Culture"
                      ? "People & Culture"
                      : text}
                  </TypographyBold>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography sx={{ height: "14px", fontSize: "10px" }} align="right">
                  {user.role === "Head of Department" ? "Head of Dep." : user.role}
                </Typography>
              </Grid>
              <Grid item>
                <TypographyBold sx={{ maxHeight: "36px" }} variant="body2" align="right">
                  {user.firstName || getFirstName(user.id)} {user.lastName || getLastName(user.id)}
                </TypographyBold>
              </Grid>
            </Grid>
          </Paper>
        </Link>
      )}
      {toggle ? (
        <Grid container justifyContent="center">
          <Grid sx={{ borderRight: `1px ${grey[400]} solid`, height: "20px" }} xs={6} item />
          <Grid sx={{ borderLeft: `1px ${grey[400]} solid`, height: "20px" }} xs={6} item />
          <Grid item>
            <Button onClick={toggle}>{show ? <RemoveIcon /> : <AddIcon />}</Button>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};
