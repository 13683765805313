import { Alert, Button, Container, Grid, Link, Snackbar, Typography, useTheme } from "@mui/material";
import { Link as ReactRouterLink } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Loading } from "../../components/Loading";
import { RolesContext } from "../../components/PrivateRoute";
import { currentStaffYear, nextStaffYear } from "../../helpers/FormatDate";
import { DepartmentInterface, InitDepartment } from "../../models/Department";
import { Divisions } from "../../models/Division";
import { InitMessageState, MessageState } from "../../models/MessageState";
import { User } from "../../models/User";
import { getNextStaffYearDepartments } from "../../services/departments";
import { getNextStaffYearDivisions } from "../../services/divisions";
import { getNextStaffYearRoles } from "../../services/roles";
import { getListOfNewUsers, getListOfUsers, getNextStaffYearUsers } from "../../services/users";
import { AddUserDialog } from "./AddUserDialog";
import { YearsUser } from "./YearsUser";
import { ThisYearsUser } from "./ThisYearsUser";
import { TypographyBold } from "../../components/TypographyBold";
import { Path } from "../../helpers/Path";

export interface UpdateUsersNextYearInterface {
  roles: string[];
  nextStaffYearRoles: string[];
  nextStaffYearDepartments: DepartmentInterface;
  nextStaffYearDivisions: Divisions;
  users: User[];
  newUsers: User[];
  nextStaffYearUsers: User[];
  addUserDialogOpen: boolean;
  loading: boolean;
  messageState: MessageState;
}

export const UpdateUsersNextYear = () => {
  const roles = useContext(RolesContext);
  const theme = useTheme();

  const [state, setState] = useState<UpdateUsersNextYearInterface>({
    loading: true,
    addUserDialogOpen: false,
    messageState: InitMessageState,
    roles: [],
    nextStaffYearRoles: [],
    nextStaffYearDepartments: InitDepartment,
    nextStaffYearDivisions: {},
    users: [],
    newUsers: [],
    nextStaffYearUsers: [],
  });

  useEffect(() => {
    const usersFetch = getListOfUsers();
    const newUsersFetch = getListOfNewUsers();
    const nextStaffYearRolesFetch = getNextStaffYearRoles();
    const nextStaffYearDivisionsFetch = getNextStaffYearDivisions();
    const nextStaffYearUsersFetch = getNextStaffYearUsers();
    const nextStaffYearDepartmentsFetch = getNextStaffYearDepartments();

    Promise.all([
      usersFetch,
      newUsersFetch,
      nextStaffYearRolesFetch,
      nextStaffYearUsersFetch,
      nextStaffYearDepartmentsFetch,
      nextStaffYearDivisionsFetch,
    ]).then(
      ([users, newUsers, nextStaffYearRoles, nextStaffYearUsers, nextStaffYearDepartments, nextStaffYearDivisions]) =>
        setState({
          ...state,
          users,
          newUsers,
          nextStaffYearRoles,
          nextStaffYearUsers,
          nextStaffYearDepartments,
          nextStaffYearDivisions,
          loading: false,
        })
    );

    // eslint-disable-next-line
  }, []);

  const {
    loading,
    users,
    newUsers,
    nextStaffYearUsers,
    nextStaffYearRoles,
    nextStaffYearDepartments,
    nextStaffYearDivisions,
    addUserDialogOpen,
    messageState,
  } = state;

  return loading ? (
    <Loading />
  ) : (
    <>
      <AddUserDialog
        addUserDialogOpen={addUserDialogOpen}
        setAddUserDialogOpen={(open: boolean) => setState({ ...state, addUserDialogOpen: open })}
        users={nextStaffYearUsers}
        setAddUser={(values: { email: string; role: string }, message: string) =>
          setState({
            ...state,
            nextStaffYearUsers: [
              ...nextStaffYearUsers,
              {
                ...values,
                id: values.email + "@sowaustralia.com",
                email: values.email + "@sowaustralia.com",
              },
            ],
            addUserDialogOpen: false,
            messageState: {
              severity: "success",
              showMessage: true,
              message,
            },
          })
        }
        setAddUserFail={(error: string) =>
          setState({
            ...state,
            addUserDialogOpen: false,
            messageState: {
              severity: "error",
              showMessage: true,
              message: error,
            },
          })
        }
        roles={nextStaffYearRoles}
        departments={nextStaffYearDepartments}
        divisions={nextStaffYearDivisions}
        staffYear={nextStaffYear}
      />
      <Snackbar
        open={messageState.showMessage}
        onClose={() =>
          setState({
            ...state,
            messageState: { ...messageState, showMessage: false },
          })
        }
        autoHideDuration={6000}
      >
        <Alert elevation={6} variant="filled" severity={messageState.severity}>
          {messageState.message}
        </Alert>
      </Snackbar>
      <Button variant="contained" component={ReactRouterLink} to={Path["Update Users"]}>
        Update Users for Current Year
      </Button>
      <Button
        sx={{
          position: "fixed",
          bottom: theme.spacing(8),
          right: theme.spacing(8),
          zIndex: 4,
        }}
        variant="contained"
        size="large"
        onClick={() => setState({ ...state, addUserDialogOpen: true })}
      >
        Add New User
      </Button>
      <Container maxWidth="md">
        <Alert sx={{ mt: theme.spacing(12), mb: theme.spacing(8) }} elevation={2} variant="filled" severity="info">
          Rearrangement/Rollover will occur at the 23rd of September every year. Your changes will affect next year's
          users. For HOD changes, please go to{" "}
          <Link
            sx={{
              textDecoration: "none",
              color: "black",
              fontSize: "large",
            }}
            underline="hover"
            component={ReactRouterLink}
            to={Path["Organisation Structure for Next Year"]}
          >
            Change Org Structure for Next Year
          </Link>
        </Alert>
        <Grid spacing={2} container>
          <Grid xs={6} item>
            <Typography>Current Staff Year: {currentStaffYear}</Typography>
            <TypographyBold>Next Staff Year Starts 18th September</TypographyBold>
          </Grid>
          <Grid xs={6} item>
            <Typography>Next Staff Year: {nextStaffYear}</Typography>
          </Grid>
          {users.map((u, j) => (
            <Grid item container spacing={2} key={j}>
              <Grid xs={6} item>
                <ThisYearsUser u={u} roles={roles} />
              </Grid>
              <Grid xs={6} item>
                <YearsUser
                  u={u}
                  users={nextStaffYearUsers}
                  roles={nextStaffYearRoles}
                  departments={nextStaffYearDepartments}
                  divisions={nextStaffYearDivisions}
                  year={nextStaffYear}
                  setUsers={(users, message) =>
                    setState({
                      ...state,
                      nextStaffYearUsers: users,
                      messageState: {
                        severity: "success",
                        showMessage: true,
                        message,
                      },
                    })
                  }
                  setUpdateUser={(user, name, value, message) =>
                    setState({
                      ...state,
                      nextStaffYearUsers:
                        nextStaffYearUsers.findIndex((u) => u.id === user.id) !== -1
                          ? ([
                              ...nextStaffYearUsers.slice(
                                0,
                                nextStaffYearUsers.findIndex((u) => u.id === user.id)
                              ),
                              {
                                ...nextStaffYearUsers[nextStaffYearUsers.findIndex((u) => u.id === user.id)],
                                [name]: value,
                              },
                              ...nextStaffYearUsers.slice(nextStaffYearUsers.findIndex((u) => u.id === user.id) + 1),
                            ] as User[])
                          : [...nextStaffYearUsers, { ...user, [name]: value }],
                      messageState: {
                        severity: "success",
                        showMessage: true,
                        message,
                      },
                    })
                  }
                  setErrorMessage={(error) =>
                    setState({
                      ...state,
                      messageState: {
                        severity: "error",
                        showMessage: true,
                        message: error,
                      },
                    })
                  }
                />
              </Grid>
            </Grid>
          ))}
          {newUsers.map((u, j) => (
            <Grid item container spacing={2} key={j}>
              <Grid xs={6} item>
                <ThisYearsUser u={u} roles={roles} />
              </Grid>
              <Grid xs={6} item>
                <YearsUser
                  u={u}
                  users={nextStaffYearUsers}
                  roles={nextStaffYearRoles}
                  departments={nextStaffYearDepartments}
                  divisions={nextStaffYearDivisions}
                  year={nextStaffYear}
                  setUsers={(newUsers, message) =>
                    setState({
                      ...state,
                      nextStaffYearUsers: newUsers,
                      messageState: {
                        severity: "success",
                        showMessage: true,
                        message,
                      },
                    })
                  }
                  setUpdateUser={(user, name, value, message) =>
                    setState({
                      ...state,
                      nextStaffYearUsers:
                        nextStaffYearUsers.findIndex((u) => u.id === user.id) !== -1
                          ? ([
                              ...nextStaffYearUsers.slice(
                                0,
                                nextStaffYearUsers.findIndex((u) => u.id === user.id)
                              ),
                              {
                                ...nextStaffYearUsers[nextStaffYearUsers.findIndex((u) => u.id === user.id)],
                                [name]: value,
                              },
                              ...nextStaffYearUsers.slice(nextStaffYearUsers.findIndex((u) => u.id === user.id) + 1),
                            ] as User[])
                          : [...nextStaffYearUsers, { ...user, [name]: value }],
                      messageState: {
                        severity: "success",
                        showMessage: true,
                        message,
                      },
                    })
                  }
                  setErrorMessage={(error) =>
                    setState({
                      ...state,
                      messageState: {
                        severity: "error",
                        showMessage: true,
                        message: error,
                      },
                    })
                  }
                />
              </Grid>
            </Grid>
          ))}
          <Grid direction="column" container alignItems="center" justifyContent="center">
            <Grid item>
              <Typography>New Users</Typography>
            </Grid>
            {nextStaffYearUsers
              .filter((nextStaffYearUser) => nextStaffYearUser.id.match("@sowaustralia.com$"))
              .filter((nextStaffYearUser) => !newUsers.map((newUser) => newUser.id).includes(nextStaffYearUser.id))
              .map((u, i) => (
                <Grid key={i} item>
                  <YearsUser
                    u={u}
                    users={nextStaffYearUsers}
                    roles={nextStaffYearRoles}
                    departments={nextStaffYearDepartments}
                    divisions={nextStaffYearDivisions}
                    year={nextStaffYear}
                    setUsers={(users, message) =>
                      setState({
                        ...state,
                        nextStaffYearUsers: users,
                        messageState: {
                          severity: "success",
                          showMessage: true,
                          message,
                        },
                      })
                    }
                    setUpdateUser={(user, name, value, message) =>
                      setState({
                        ...state,
                        nextStaffYearUsers:
                          nextStaffYearUsers.findIndex((u) => u.id === user.id) !== -1
                            ? ([
                                ...nextStaffYearUsers.slice(
                                  0,
                                  nextStaffYearUsers.findIndex((u) => u.id === user.id)
                                ),
                                {
                                  ...nextStaffYearUsers[nextStaffYearUsers.findIndex((u) => u.id === user.id)],
                                  [name]: value,
                                },
                                ...nextStaffYearUsers.slice(nextStaffYearUsers.findIndex((u) => u.id === user.id) + 1),
                              ] as User[])
                            : [...nextStaffYearUsers, { ...user, [name]: value }],
                        messageState: {
                          severity: "success",
                          showMessage: true,
                          message,
                        },
                      })
                    }
                    setErrorMessage={(error) =>
                      setState({
                        ...state,
                        messageState: {
                          severity: "error",
                          showMessage: true,
                          message: error,
                        },
                      })
                    }
                  />
                </Grid>
              ))}
            {nextStaffYearUsers
              .filter((nextStaffYearUser) => !nextStaffYearUser.id.match("@sowaustralia.com$"))
              .filter((nextStaffYearUser) => !users.map((newUser) => newUser.id).includes(nextStaffYearUser.id))
              .filter((nextStaffYearUser) => !newUsers.map((newUser) => newUser.id).includes(nextStaffYearUser.id))
              .map((u, i) => (
                <Grid key={i} item>
                  <YearsUser
                    u={u}
                    users={nextStaffYearUsers}
                    roles={nextStaffYearRoles}
                    departments={nextStaffYearDepartments}
                    divisions={nextStaffYearDivisions}
                    year={nextStaffYear}
                    setUsers={(users, message) =>
                      setState({
                        ...state,
                        nextStaffYearUsers: users,
                        messageState: {
                          severity: "success",
                          showMessage: true,
                          message,
                        },
                      })
                    }
                    setUpdateUser={(user, name, value, message) =>
                      setState({
                        ...state,
                        nextStaffYearUsers:
                          nextStaffYearUsers.findIndex((u) => u.id === user.id) !== -1
                            ? ([
                                ...nextStaffYearUsers.slice(
                                  0,
                                  nextStaffYearUsers.findIndex((u) => u.id === user.id)
                                ),
                                {
                                  ...nextStaffYearUsers[nextStaffYearUsers.findIndex((u) => u.id === user.id)],
                                  [name]: value,
                                },
                                ...nextStaffYearUsers.slice(nextStaffYearUsers.findIndex((u) => u.id === user.id) + 1),
                              ] as User[])
                            : [...nextStaffYearUsers, { ...user, [name]: value }],
                        messageState: {
                          severity: "success",
                          showMessage: true,
                          message,
                        },
                      })
                    }
                    setErrorMessage={(error) =>
                      setState({
                        ...state,
                        messageState: {
                          severity: "error",
                          showMessage: true,
                          message: error,
                        },
                      })
                    }
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
