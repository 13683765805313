import { TabContext, TabList } from "@mui/lab";
import { Box, Button, Container, Link, Grid, Tab, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link as ReactRouterLink, useHistory } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { Path } from "../../helpers/Path";
import { eventFormApproved, getEventForms } from "../../services/eventForm";
import { EventPanel } from "./EventPanel";
import { EventPost } from "./EventPost";
import { EventFormDialog } from "./EventFormDialog";
import { DepartmentsContext, RolesContext, UserContext } from "../../components/PrivateRoute";
import { Staff } from "../../models/Staff";
import { EventFormModel, SubFormStatus, TabPanelValue } from "../../models/EventFormModel";
import { DATA_AND_IT, EVENTS } from "../../data/Departments";
import { currentStaffYear, pastStaffYearsSince2023 } from "../../helpers/FormatDate";
import { DIRECTOR, HEAD_OF_DEPARTMENT, HEAD_OF_DIVISION } from "../../data/Roles";
import { atLeast } from "../../helpers/Role";

export interface EventFormInterface {
  loading: boolean;
  completedEvents: EventFormModel[];
  inProgressEvents: EventFormModel[];
  currentTabPanel: string;
  page: number;
  rowsPerPage: number;
  postDialogOpen: boolean;
  eventFormDialogOpen: boolean;
  selectedEventForm?: EventFormModel;
  postDialogEdit: boolean;
}

export const EventForm = () => {
  const user = useContext(UserContext) as Staff;
  const departments = useContext(DepartmentsContext);
  const roles = useContext(RolesContext);

  const [state, setState] = useState<EventFormInterface>({
    loading: true,
    completedEvents: [],
    inProgressEvents: [],
    currentTabPanel: TabPanelValue.IN_PROGRESS,
    page: 0,
    rowsPerPage: 10,
    postDialogOpen: false,
    eventFormDialogOpen: false,
    postDialogEdit: false,
  });

  const { loading, page, rowsPerPage, completedEvents, inProgressEvents, eventFormDialogOpen, selectedEventForm } =
    state;

  const history = useHistory();

  useEffect(() => {
    getEventForms().then((forms) => {
      setState({
        ...state,
        completedEvents: forms.filter(eventFormApproved).sort((a, b) => (a.submittedTime < b.submittedTime ? 1 : -1)),
        inProgressEvents: forms
          .filter((form) => !eventFormApproved(form))
          .sort((a, b) => (a.submittedTime < b.submittedTime ? 1 : -1)),
        currentTabPanel: isReviewer ? TabPanelValue.TO_APPROVE : TabPanelValue.IN_PROGRESS,
        loading: false,
      });
    });
    // eslint-disable-next-line
  }, []);

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setState({
      ...state,
      currentTabPanel: newValue,
    });
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setState({
      ...state,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      page: 0,
      rowsPerPage: +event.target.value,
    });
  };

  const navigateToStageForm = (id: string, formType: string, year: string) =>
    history.push(`${Path["Event Sub Form"]}/${year}/${formType}/${id}`);

  const departmentToTypeStatus = {
    [departments.MARKETING]: "marketingStatus" as keyof EventFormModel,
    [departments.FINANCE]: "financeStatus" as keyof EventFormModel,
    // [RISK]: "riskStatus" as keyof EventFormModel,
    [DATA_AND_IT]: "registrationStatus" as keyof EventFormModel,
  };

  const isAdmin =
    (user.department === DATA_AND_IT && user.role === HEAD_OF_DEPARTMENT) ||
    atLeast(roles, HEAD_OF_DIVISION, user.role);

  const subFormsToReview =
    departmentToTypeStatus[user.department] &&
    inProgressEvents.filter((e) => e[departmentToTypeStatus[user.department]] === SubFormStatus.SUBMITTED);

  const subFormsApproved =
    departmentToTypeStatus[user.department] &&
    inProgressEvents.filter((e) => e[departmentToTypeStatus[user.department]] === SubFormStatus.APPROVED);

  const isReviewer =
    user.department === departments.MARKETING ||
    user.department === departments.FINANCE ||
    // user.department === RISK ||
    user.department === DATA_AND_IT ||
    user.role === DIRECTOR;

  return loading ? (
    <Loading />
  ) : (
    <>
      <EventFormDialog
        eventForm={selectedEventForm}
        dialogOpen={eventFormDialogOpen}
        closeDialog={() => setState({ ...state, eventFormDialogOpen: false })}
        eventFormState={state}
        eventFormSetState={setState}
      />
      <EventPost state={state} setState={setState} />
      <Container maxWidth="lg">
        <Grid container spacing={2} sx={{ mb: 4 }}>
          <Grid item xs={6}>
            <Typography variant="h6">Submit, update and review Event Forms here!</Typography>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" onClick={() => setState({ ...state, postDialogOpen: true })}>
                Make event request
              </Button>
            </Box>
          </Grid>
        </Grid>
        <TabContext value={state.currentTabPanel}>
          <Box>
            <TabList onChange={handleChange} variant="fullWidth">
              <Tab
                label={TabPanelValue.IN_PROGRESS}
                value={TabPanelValue.IN_PROGRESS}
                key={TabPanelValue.IN_PROGRESS}
              />
              <Tab label={TabPanelValue.COMPLETED} value={TabPanelValue.COMPLETED} key={TabPanelValue.COMPLETED} />
              {isReviewer && (
                <Tab label={TabPanelValue.TO_APPROVE} value={TabPanelValue.TO_APPROVE} key={TabPanelValue.TO_APPROVE} />
              )}
              {isReviewer && (
                <Tab label={TabPanelValue.APPROVED} value={TabPanelValue.APPROVED} key={TabPanelValue.APPROVED} />
              )}
            </TabList>
          </Box>
          <EventPanel
            openEventFormDialog={(selectedEventForm) =>
              setState({ ...state, selectedEventForm, eventFormDialogOpen: true })
            }
            tabPanelValue={TabPanelValue.IN_PROGRESS}
            events={inProgressEvents.filter(
              (e) =>
                e.submittee.id === user.id ||
                (e.submittee as Staff).department === user.department ||
                user.department === EVENTS ||
                isReviewer ||
                isAdmin
            )}
            page={page}
            rowsPerPage={rowsPerPage}
            navigateToStageForm={navigateToStageForm}
            year={currentStaffYear.toString()}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            user={user}
            departments={departments}
          />
          <EventPanel
            openEventFormDialog={(selectedEventForm) =>
              setState({ ...state, selectedEventForm, eventFormDialogOpen: true })
            }
            tabPanelValue={TabPanelValue.COMPLETED}
            events={completedEvents.filter(
              (e) =>
                e.submittee.id === user.id ||
                (e.submittee as Staff).department === user.department ||
                user.department === EVENTS ||
                isReviewer ||
                isAdmin
            )}
            page={page}
            rowsPerPage={rowsPerPage}
            navigateToStageForm={navigateToStageForm}
            year={currentStaffYear.toString()}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            user={user}
            departments={departments}
          />
          {(isReviewer || isAdmin) && (
            <EventPanel
              openEventFormDialog={(selectedEventForm) =>
                setState({ ...state, selectedEventForm, eventFormDialogOpen: true })
              }
              tabPanelValue={TabPanelValue.TO_APPROVE}
              events={subFormsToReview}
              page={page}
              rowsPerPage={rowsPerPage}
              navigateToStageForm={navigateToStageForm}
              year={currentStaffYear.toString()}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              user={user}
              departments={departments}
            />
          )}
          {(isReviewer || isAdmin) && (
            <EventPanel
              openEventFormDialog={(selectedEventForm) =>
                setState({ ...state, selectedEventForm, eventFormDialogOpen: true })
              }
              tabPanelValue={TabPanelValue.APPROVED}
              events={subFormsApproved}
              page={page}
              rowsPerPage={rowsPerPage}
              navigateToStageForm={navigateToStageForm}
              year={currentStaffYear.toString()}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              user={user}
              departments={departments}
            />
          )}
        </TabContext>
      </Container>
      {(user.department === departments.EVENTS || isAdmin) && <Typography>History</Typography>}
      {(user.department === departments.EVENTS || isAdmin) && (
        <Grid container direction="column">
          {pastStaffYearsSince2023.map((y, i) => (
            <Grid item key={i}>
              <Link to={Path["Past Year's Events"] + "/" + y} component={ReactRouterLink}>
                {y}
              </Link>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};
