import { User } from "../models/User";
import { InitModuleStats, ModuleStats } from "../models/ModuleStats";
import { ModuleContents } from "../models/ModuleContents";
import { ModuleContent } from "../models/ModuleContent";
import { ModuleModel, ReviewerModuleModel } from "../models/ModuleModel";
import { ModuleSAQ } from "../models/ModuleSAQ";
import { ModuleListModel } from "../models/ModuleListModel";
import { db } from "./firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { getListOfUsersByStaffYear } from "./users";
import { ModuleMCQ } from "../models/ModuleMCQ";
import { MCQChoice } from "../models/MCQChoice";
import {
  getMondaysCurrentMonth,
  getMondaysNextMonth,
  getNextMonth,
  moduleMonths,
  moduleMonthsUntilNextMonth,
  oneWeekBefore,
} from "../helpers/FormatDate";

export interface ModuleMonthsThemes {
  October: string;
  November: string;
  December: string;
  January: string;
  February: string;
  March: string;
  April: string;
  May: string;
  June: string;
  July: string;
  August: string;
}

export const moduleMonthsThemes: ModuleMonthsThemes = {
  October: "Philosophy of Ministry",
  November: "Team Building and Formation",
  December: "Understanding Demographics",
  January: "Outreach on Campus",
  February: "Leading in the Word",
  March: "Principles of Discipleship",
  April: "Mid-term Health Check",
  May: "Listening Well",
  June: "Encouraging People",
  July: "Handover",
  August: "Legacy",
};

export interface ModuleGroup {
  October: ModuleListModel[];
  November: ModuleListModel[];
  December: ModuleListModel[];
  January: ModuleListModel[];
  February: ModuleListModel[];
  March: ModuleListModel[];
  April: ModuleListModel[];
  May: ModuleListModel[];
  June: ModuleListModel[];
  July: ModuleListModel[];
}

const getContents = async (id: string): Promise<ModuleContents[]> => {
  const moduleContents: ModuleContents[] = [];
  const contents = await getDocs(collection(db, "modules", id, "contents"));

  for (const contentDoc of contents.docs) {
    const contentDocs = await getDocs(collection(db, "modules", id, "contents", contentDoc.id, "content"));
    let content = contentDocs.docs.map((d) => {
      return { ...d.data() } as ModuleContent;
    });
    content.sort((a, b) => {
      return Number(a.id) - Number(b.id);
    });
    const saqDocs = await getDocs(collection(db, "modules", id, "contents", contentDoc.id, "quiz"));
    let saq: ModuleSAQ[] | undefined = undefined;
    if (!saqDocs.empty) {
      saq = saqDocs.docs.map((d) => {
        return { ...d.data(), id: d.id } as ModuleSAQ;
      });
      saq.sort((a, b) => {
        return Number(a.id) - Number(b.id);
      });
    }
    moduleContents.push({
      ...contentDoc.data(),
      content,
      saq,
    } as ModuleContents);
  }
  moduleContents.sort((a, b) => {
    return a.step - b.step;
  });
  return moduleContents;
};

const getQuestions = async (id: string): Promise<ModuleMCQ[]> => {
  const questions: ModuleMCQ[] = [];
  const questionsDoc = await getDocs(collection(db, "modules", id, "questions"));

  for (const questionDoc of questionsDoc.docs) {
    questions.push({
      ...questionDoc.data(),
      id: questionDoc.id,
      choices: await getChoices(id, questionDoc.id),
    } as ModuleMCQ);
  }
  questions.sort((a, b) => {
    return Number(a.id) - Number(b.id);
  });
  return questions;
};

const getChoices = async (moduleId: string, questionId: string): Promise<MCQChoice[]> => {
  const choices: MCQChoice[] = [];
  const choicesDoc = await getDocs(collection(db, "modules", moduleId, "questions", questionId, "choices"));

  for (const doc of choicesDoc.docs) {
    choices.push({
      id: doc.id,
      text: doc.data().text,
    });
  }
  choices.sort((a, b) => {
    return Number(a.id) - Number(b.id);
  });
  return choices;
};

export const getModule = (id: string): Promise<ModuleModel | undefined> => {
  return new Promise(async (resolve, reject) => {
    const res = await getDoc(doc(db, "modules", id));
    const contents = await getContents(id);
    const questions = await getQuestions(id);
    if (res.exists()) {
      resolve({
        ...res.data(),
        id: res.id,
        contents,
        questions,
      } as ModuleModel);
    } else {
      resolve(undefined);
    }
  });
};

export const getStats = (user: User, moduleId: string): Promise<ModuleStats | undefined> =>
  new Promise((resolve, reject) => {
    getDoc(doc(db, "moduleStats", user.id, "moduleStats", moduleId))
      .then((stats) => {
        resolve(convertStatsFromFirestore(stats.data()));
      })
      .catch((error) => {
        reject(error);
      });
  });

const convertStatsFromFirestore = (stat: DocumentData | undefined): ModuleStats | undefined => {
  return stat
    ? ({
        ...stat,
        completedTime: stat.completedTime ? stat.completedTime.toDate() : undefined,
      } as ModuleStats)
    : undefined;
};

export const getAllModules = async (): Promise<ModuleListModel[][]> => {
  const modules = (await getDocs(collection(db, "modules"))).docs.map((doc) => {
    return {
      ...doc.data(),
      id: doc.id,
    } as ModuleListModel;
  });

  const groupedByMonth = modules.reduce(
    (acc, obj) => {
      const { month } = obj;
      if (!acc[month as keyof ModuleGroup]) {
        acc[month as keyof ModuleGroup] = [];
      }
      acc[month as keyof ModuleGroup].push(obj);
      return acc;
    },
    {
      October: [],
      November: [],
      December: [],
      January: [],
      February: [],
      March: [],
      April: [],
      May: [],
      June: [],
      July: [],
    } as ModuleGroup
  );

  return moduleMonths.map((month) => groupedByMonth[month as keyof ModuleGroup]);
};

export interface UserModuleStats {
  user: User;
  stats: ModuleStats[];
}

function partition<T>(array: T[], predicate: (value: T) => boolean): [T[], T[]] {
  return array.reduce<[T[], T[]]>(
    (acc, value) => {
      if (predicate(value)) {
        acc[0].push(value); // Elements that match the condition
      } else {
        acc[1].push(value); // Elements that don't match the condition
      }
      return acc;
    },
    [[], []] // Initial values for the two arrays
  );
}

const usersModuleStats = async (users: User[]) => {
  let usersModuleStats: UserModuleStats[] = [];
  for (const user of users) {
    usersModuleStats.push({
      user,
      stats: (await getDocs(collection(db, "moduleStats", user.id, "moduleStats"))).docs.map(
        (d) => ({ ...d.data(), id: d.id } as ModuleStats)
      ),
    });
  }
  return usersModuleStats;
};

export const getReviewerModules = async (year: string, month: string): Promise<ReviewerModuleModel[]> => {
  const modules = (await getDocs(query(collection(db, "modules"), where("month", "==", month)))).docs.map((doc) => {
    return {
      ...doc.data(),
      id: doc.id,
    } as ModuleModel;
  });
  const users = await getListOfUsersByStaffYear(Number(year));
  const usersModuleStatsList = await usersModuleStats(users);
  return modules.map((m) => {
    let filteredByRole = usersModuleStatsList.filter((s) => m.availableRoles.includes(s.user.role));
    let [usersWhoCompleted, usersWhoDidntComplete] = partition<UserModuleStats>(filteredByRole, (s) =>
      s.stats.filter((ms) => ms.id === m.id).some((ms) => ms.complete)
    );
    return {
      ...m,
      usersWhoCompleted: usersWhoCompleted.map((uwc) => uwc.user),
      usersWhoDidntComplete: usersWhoDidntComplete.map((uwdc) => uwdc.user),
    };
  });
};

export const getNModulesToComplete = async (user: User): Promise<number> => {
  const modules = (await getModules(user)).flat();
  return modules.filter((m) => !m.moduleStats || !m.moduleStats.complete).length;
};

const getOrder = (month: string) => {
  let orders = [];
  let today = new Date();
  if (month === today.toLocaleString("default", { month: "long" })) {
    if (oneWeekBefore(getMondaysCurrentMonth()[0]) < today) {
      orders.push(1);
    }
    if (oneWeekBefore(getMondaysCurrentMonth()[1]) < today) {
      orders.push(2);
    }
  } else if (month === getNextMonth().toLocaleString("default", { month: "long" })) {
    if (oneWeekBefore(getMondaysNextMonth()[0]) < today) {
      orders.push(1);
    }
    if (oneWeekBefore(getMondaysNextMonth()[1]) < today) {
      orders.push(2);
    }
  } else {
    orders.push(1, 2);
  }
  return orders;
};

export const getModules = async (user: User): Promise<ModuleListModel[][]> => {
  const stats = await getDocs(collection(db, "moduleStats", user.id, "moduleStats"));
  let modulesList: ModuleListModel[][] = [];
  for (const month of moduleMonthsUntilNextMonth) {
    const modules = await getDocs(
      query(
        collection(db, "modules"),
        where("availableRoles", "array-contains", user.role),
        where("month", "==", month),
        getOrder(month).length > 0 ? where("order", "in", getOrder(month)) : where("order", "==", -1)
      )
    );
    let monthModules = modules.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
        moduleStats: convertStatsFromFirestore(stats.docs.find((e) => e.id === doc.id)?.data()),
      } as ModuleModel;
    });
    monthModules.sort((a, b) => a.order - b.order);
    modulesList.push(monthModules);
  }
  return modulesList;
};

export const getCompletedModules = async (user: User): Promise<ModuleListModel[]> => {
  const modules = await getDocs(query(collection(db, "modules"), where("availableRoles", "array-contains", user.role)));
  const stats = await getDocs(collection(db, "moduleStats", user.id, "moduleStats"));
  return modules.docs
    .map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
        moduleStats: convertStatsFromFirestore(stats.docs.find((e) => e.id === doc.id)?.data()),
      } as ModuleModel;
    })
    .filter((m) => m.moduleStats.complete);
};

export const startModule = (user: User, module: ModuleListModel): Promise<ModuleStats> =>
  new Promise((resolve, reject) => {
    getDoc(doc(db, "moduleStats", user.id, "moduleStats", module.id as string)).then((stat) => {
      if (stat.exists()) {
        resolve(stat.data() as ModuleStats);
      } else {
        setDoc(doc(db, "moduleStats", user.id), {})
          .then(() =>
            setDoc(doc(db, "moduleStats", user.id, "moduleStats", module.id as string), InitModuleStats)
              .then(() => {
                resolve(InitModuleStats);
              })
              .catch((error) => reject(error))
          )
          .catch((error) => reject(error));
      }
    });
  });

export const incrementCurrentStep = (user: User, module: ModuleModel, maxStep: number): Promise<ModuleModel> =>
  new Promise((resolve, reject) => {
    if (module.moduleStats) {
      const nextStep = module.moduleStats.currentStep + 1;
      if (maxStep < nextStep) {
        updateDoc(doc(db, "moduleStats", user.id, "moduleStats", module.id as string), {
          currentStep: nextStep,
        })
          .then(() => {
            resolve({
              ...module,
              moduleStats: {
                ...module.moduleStats,
                currentStep: nextStep,
              } as ModuleStats,
            });
          })
          .catch((error) => reject(error));
      } else {
        resolve({
          ...module,
          moduleStats: {
            ...module.moduleStats,
            currentStep: nextStep,
          } as ModuleStats,
        });
      }
    } else {
      reject("module stats not found");
    }
  });

export const updateSAQAnswers = async (
  user: User,
  module: ModuleModel,
  q: { [x: string]: string },
  currentStep: number
) => {
  if (module.moduleStats.answers === undefined) {
    module.moduleStats.answers = {};
  }
  module.moduleStats.answers[currentStep] = q;
  await updateDoc(doc(db, "moduleStats", user.id, "moduleStats", module.id as string), {
    answers: module.moduleStats.answers,
  });
  return module;
};

export const updateQuestionAnswers = async (
  user: User,
  module: ModuleModel,
  answersForQuestions: { [questionId: string]: string | string[] }
) => {
  await updateDoc(doc(db, "moduleStats", user.id, "moduleStats", module.id as string), {
    answersForQuestions,
  });
};

export const completeModule = (user: User, module: ModuleModel): Promise<ModuleModel> =>
  new Promise((resolve, reject) => {
    const cTime = new Date();
    updateDoc(doc(db, "moduleStats", user.id, "moduleStats", module.id as string), {
      complete: true,
      completedTime: cTime,
    })
      .then(() => {
        resolve({
          ...module,
          moduleStats: {
            ...module.moduleStats,
            complete: true,
            completedTime: cTime,
          } as ModuleStats,
        });
      })
      .catch((error) => reject(error));
  });

export const deleteModule = (module_id: string | undefined): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    if (module_id === "" || module_id === undefined) {
      resolve("New module");
    } else {
      const module = await getDoc(doc(db, "modules", module_id as string));

      if (module.exists()) {
        const contents = await getDocs(collection(db, "modules", module_id as string, "contents"));
        for (const d of contents.docs) {
          const content = await getDocs(collection(db, "modules", module_id as string, "contents", d.id, "content"));
          for (const c_doc of content.docs) {
            await deleteDoc(doc(db, "modules", module_id as string, "contents", d.id, "content", c_doc.id));
          }
          const saq = await getDocs(collection(db, "modules", module_id as string, "contents", d.id, "quiz"));
          for (const q_doc of saq.docs) {
            await deleteDoc(doc(db, "modules", module_id as string, "contents", d.id, "quiz", q_doc.id));
          }
          await deleteDoc(doc(db, "modules", module_id as string, "contents", d.id));
        }

        const mcqs = await getDocs(collection(db, "modules", module_id as string, "questions"));
        for (const mcq of mcqs.docs) {
          const choices = await getDocs(collection(db, "modules", module_id as string, "questions", mcq.id, "choices"));
          for (const choice of choices.docs) {
            await deleteDoc(doc(db, "modules", module_id as string, "questions", mcq.id, "choices", choice.id));
          }
          await deleteDoc(doc(db, "modules", module_id as string, "questions", mcq.id));
        }

        await deleteDoc(doc(db, "modules", module_id as string));
        resolve("Module deleted");
      } else {
        resolve("Module not found");
      }
    }
  });
};

export const saveModule = (module: ModuleModel): Promise<ModuleModel> => {
  return new Promise(async (resolve, reject) => {
    const { id, contents, questions, moduleStats, ...moduleForFirebase } = module;
    if (module.id === "" || module.id === undefined) {
      const id = (await addDoc(collection(db, "modules"), moduleForFirebase)).id;
      module.id = id;
    } else {
      const snap = await getDoc(doc(db, "modules", module.id));

      if (snap.exists()) {
        await updateDoc(doc(db, "modules", module.id), { ...moduleForFirebase });
      } else {
        await setDoc(doc(db, "modules", module.id), moduleForFirebase);
      }
    }

    for (const c of contents.values()) {
      const { content, saq, ...cWithoutContent } = c;
      const contentDoc = await addDoc(collection(db, "modules", module.id, "contents"), cWithoutContent);
      for (const [j, con] of content.entries()) {
        await setDoc(doc(db, "modules", module.id, "contents", contentDoc.id, "content", j.toString()), con);
      }
      if (saq && saq.length > 0) {
        for (const [j, q] of saq.entries()) {
          await setDoc(doc(db, "modules", module.id, "contents", contentDoc.id, "quiz", j.toString()), q);
        }
      }
    }
    if (questions) {
      for (const q of questions.values()) {
        const { id: questionId, choices, ...question } = q;
        await setDoc(doc(db, "modules", module.id, "questions", questionId), { ...question });
        for (const c of q.choices) {
          const { id: choiceId, text } = c;
          await setDoc(doc(db, "modules", module.id, "questions", questionId, "choices", choiceId), { text });
        }
      }
    }
    resolve(module);
  });
};
