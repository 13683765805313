export const formatTimeAMPM = (date: Date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  let minutesString: string | number = minutes < 10 ? "0" + minutes : minutes;
  return hours + ":" + minutesString + " " + ampm;
};

// yyyy-mm-dd format
export const formatDateDash = (date: Date) => {
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();
  let year = date.getFullYear();
  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }
  return [year, month, day].join("-");
};

export const divisionsStartYear = 2023;

export const departmentsStartYear = 2019;

export const rolesStartYear = 2019;

// Jan = 0, Feb = 1, ... , Dec = 11
export const currentMonth = new Date().getMonth();

export const currentYear = new Date().getFullYear();

export const startYear = 2008;

export const formatDate = (date: Date) => date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

export const formatDateComma = (date: Date) =>
  date.getFullYear() + ", " + (date.getMonth() + 1) + ", " + date.getDate();

export const formatDateTime = (date?: Date) =>
  !date ? "" : date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + " " + formatTimeAMPM(date);

export const yearsSinceStartYearUntilLastYear = () => {
  let years = [];
  for (let i = startYear; i < currentYear; ++i) {
    years.push(i);
  }
  return years;
};

export const staffYearsSinceStartYearUntilLastYear = () => {
  let years = [];
  for (let i = startYear; i < currentStaffYear; ++i) {
    years.push(i);
  }
  return years;
};

export const staffYearsSince = (year: number) => {
  let years = [];
  for (let i = year; i <= currentStaffYear; ++i) {
    years.push(i);
  }
  return years;
};

export const staffYearsSinceStartYear = () => {
  let years = [];
  for (let i = startYear; i <= currentStaffYear; ++i) {
    years.push(i);
  }
  return years;
};

export const staffYearsSinceStartYearAndNextYear = () => {
  let years = [];
  for (let i = startYear; i <= currentStaffYear + 1; ++i) {
    years.push(i);
  }
  return years;
};

export const yearsSinceStartYearUntilYear = (year: number) => {
  let years = [];
  for (let i = startYear; i <= year && i <= currentStaffYear; ++i) {
    years.push(i);
  }
  return years;
};

export const yearsSinceStartYear = () => {
  let years = [];
  for (let i = startYear; i <= new Date().getFullYear(); ++i) {
    years.push(i);
  }
  return years;
};

export const yearsSinceStartYearAndNextYear = () => {
  let years = [];
  for (let i = startYear; i <= new Date().getFullYear() + 1; ++i) {
    years.push(i);
  }
  return years;
};

export const staffRollover = (new Date().getMonth() >= 8 && new Date().getDate() >= 18) || new Date().getMonth() >= 9; // September

export const currentStaffYear = staffRollover ? currentYear + 1 : currentYear;

export const nextStaffYear = currentStaffYear + 1;

export const pastStaffYearsSince2021 = staffYearsSinceStartYearUntilLastYear().filter((y) => y >= 2021);

export const pastStaffYearsSinceYear = (year: number) =>
  staffYearsSinceStartYearUntilLastYear().filter((y) => y >= year);

export const pastStaffYearsSince2022 = staffYearsSinceStartYearUntilLastYear().filter((y) => y >= 2022);

export const pastStaffYearsSince2023 = staffYearsSinceStartYearUntilLastYear().filter((y) => y >= 2023);

export const pastSOWYears = staffRollover ? yearsSinceStartYear() : yearsSinceStartYearUntilLastYear();

export const pastSOWYearsUntilYear = (year: number) => staffYearsSinceStartYearUntilLastYear().filter((y) => y < year);

export const pastSOWYearsIncludeThisYear = staffRollover ? yearsSinceStartYearAndNextYear() : yearsSinceStartYear();

export const timeSince = (date: Date) => {
  var seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return interval < 2 ? "1 year" : Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return interval < 2 ? "1 month" : Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return interval < 2 ? "1 day" : Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return interval < 2 ? "1 hour" : Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return interval < 2 ? "1 minute" : Math.floor(interval) + " minutes";
  }
  return interval < 2 ? "1 second" : Math.floor(interval) + " seconds";
};

export const calculateDateString = (d?: Date) => {
  if (d) {
    var dd = String(d.getDate()).padStart(2, "0");
    var mm = String(d.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = d.getFullYear();
    return dd + "/" + mm + "/" + yyyy;
  }
  return "";
};

export const moduleMonths = [
  "October",
  "November",
  "December",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
];

export const moduleMonthsUntilNextMonth = moduleMonths.slice(0, ((4 + currentMonth) % 12) + 1);

function getMondays(date: Date) {
  var d = date,
    month = date.getMonth(),
    mondays = [];

  d.setDate(1);

  // Get the first Monday in the month
  while (d.getDay() !== 1) {
    d.setDate(d.getDate() + 1);
  }

  // Get all the other Mondays in the month
  while (d.getMonth() === month) {
    mondays.push(new Date(d.getTime()));
    d.setDate(d.getDate() + 7);
  }

  return mondays;
}

export function getMondaysCurrentMonth() {
  return getMondays(new Date());
}

export function getNextMonth() {
  var now = new Date();
  if (now.getMonth() === 11) {
    return new Date(now.getFullYear() + 1, 0, 1);
  } else {
    return new Date(now.getFullYear(), now.getMonth() + 1, 1);
  }
}

export function getMondaysNextMonth() {
  return getMondays(getNextMonth());
}

export function oneWeekBefore(date1: Date) {
  date1.setDate(date1.getDate() - 7);
  return date1;
}
