import { Staff } from "./Staff";

export enum SubFormType {
  MARKETING = "marketing",
  FINANCE = "finance",
  REGISTRATION = "registration",
}

export enum SubFormStatus {
  SUBMITTED = "Submitted",
  APPROVED = "Approved",
  REQUEST_FOR_CHANGE = "Request for change",
}

export enum EventAudience {
  INTERNAL = "Internal",
  EXTERNAL = "External",
  ALL = "All",
}

export enum TabPanelValue {
  IN_PROGRESS = "In Progress",
  COMPLETED = "Completed",
  TO_APPROVE = "To Approve",
  APPROVED = "Approved",
}

export interface EventFormModel {
  id: string;
  submittee: Staff;
  name: string;
  theme: string;
  purpose: string;
  goals: string;
  location: string;
  registrationGoal: number;
  audience: EventAudience;
  submittedTime: Date;
  updatedTime: Date;
  eventStartTime: Date;
  eventEndTime: Date;
  registrationOpenTime: Date;
  registrationCloseTime: Date;
  notes: string;
  marketingStatus?: SubFormStatus;
  financeStatus?: SubFormStatus;
  // riskStatus?: SubFormStatus;
  registrationStatus?: SubFormStatus;
}

export interface EventFormSubmit {
  submittee: Staff;
  name: string;
  theme: string;
  purpose: string;
  goals: string;
  location: string;
  registrationGoal: number;
  audience: EventAudience;
  eventStartTime: Date;
  eventEndTime: Date;
  registrationOpenTime: Date;
  registrationCloseTime: Date;
  notes: string;
}

export interface SubFormModel {
  status?: SubFormStatus;
  requestForChangeReason?: string;
  submittedDate?: Date;
  approvedDate?: Date;
}

export interface MarketingSubFormModel extends SubFormModel {
  keyMessage: string;
  theme: string;
  brochures: boolean;
  brochuresSpecifications: string;
  flyers: boolean;
  flyersSpecifications: string;
  invitations: boolean;
  invitationsSpecifications: string;
  signage: boolean;
  signageSpecifications: string;
  businessCard: boolean;
  businessCardSpecifications: string;
  pamphlet: boolean;
  pamphletSpecifications: string;
  poster: boolean;
  posterSpecifications: string;
  postcard: boolean;
  postcardSpecifications: string;
  merchandise: boolean;
  merchandiseSpecifications: string;
  otherPrinted: boolean;
  otherPrintedSpecifications: string;
  logo: boolean;
  powerpoint: boolean;
  promoVideo: boolean;
  promoVideoSpecifications: string;
  recapVideo: boolean;
  recapVideoSpecifications: string;
  letterhead: boolean;
  facebookEventBanner: boolean;
  instagramPost: boolean;
  websiteBanner: boolean;
  otherDigital: boolean;
  otherDigitalSpecifications: string;
  designNeedsApproval: boolean;
  photography: boolean;
  videography: boolean;
  instagram: boolean;
  otherPromotion: boolean;
  otherPromotionSpecifications: string;
  bibleVerse: boolean;
  themePurpose: boolean;
  saveTheDate: boolean;
  throwbackPhotos: boolean;
  directorsAddress: boolean;
  registrationCountdown: boolean;
  prizeInventive: boolean;
  sponsorships: boolean;
  rulesFaqs: boolean;
  merchandisePromo: boolean;
  paidAds: boolean;
  campusPromotion: boolean;
  finalRemindersInfo: boolean;
  eventPhotos: boolean;
  promoStart: string;
  importantPromotionInformation: string;
  otherPromotionInformation: string;
}

export const InitMarketingSubFormModel: MarketingSubFormModel = {
  keyMessage: "",
  theme: "",
  brochures: false,
  brochuresSpecifications: "",
  flyers: false,
  flyersSpecifications: "",
  invitations: false,
  invitationsSpecifications: "",
  signage: false,
  signageSpecifications: "",
  businessCard: false,
  businessCardSpecifications: "",
  pamphlet: false,
  pamphletSpecifications: "",
  poster: false,
  posterSpecifications: "",
  postcard: false,
  postcardSpecifications: "",
  merchandise: false,
  merchandiseSpecifications: "",
  otherPrinted: false,
  otherPrintedSpecifications: "",
  logo: false,
  powerpoint: false,
  promoVideo: false,
  promoVideoSpecifications: "",
  recapVideo: false,
  recapVideoSpecifications: "",
  letterhead: false,
  facebookEventBanner: false,
  instagramPost: false,
  websiteBanner: false,
  otherDigital: false,
  otherDigitalSpecifications: "",
  designNeedsApproval: false,
  photography: false,
  videography: false,
  instagram: false,
  otherPromotion: false,
  otherPromotionSpecifications: "",
  bibleVerse: false,
  themePurpose: false,
  saveTheDate: false,
  throwbackPhotos: false,
  directorsAddress: false,
  registrationCountdown: false,
  prizeInventive: false,
  sponsorships: false,
  rulesFaqs: false,
  merchandisePromo: false,
  paidAds: false,
  campusPromotion: false,
  finalRemindersInfo: false,
  eventPhotos: false,
  promoStart: "",
  importantPromotionInformation: "",
  otherPromotionInformation: "",
};

export interface FinanceSubFormModel extends SubFormModel {
  salesRevenues: FinanceSubFormEntry[];
  directExpenses: FinanceSubFormEntry[];
  indirectExpenses: FinanceSubFormEntry[];
  equipmentMaterials: FinanceSubFormEntry[];
  travelOthers: FinanceSubFormEntry[];
  salesRevsTotal: number;
  directExpsTotal: number;
  indirectExpsTotal: number;
  equipMaterialsTotal: number;
  travelOthersTotal: number;
  total: number;
  budgetManagerApprovalStatus: SubFormStatus;
  approvedByBudgetManagerTime?: Date;
  directorApprovalStatus: SubFormStatus;
  approvedByDirectorTime?: Date;
}

export interface FinanceSubFormEntry {
  type: string;
  unitPrice: number;
  quantity: number;
  total: number;
  justification: string;
}

const InitFinanceSubFormEntry = {
  type: "",
  unitPrice: 0,
  quantity: 0,
  total: 0,
  justification: "",
};

export const InitFinanceSubFormModel: FinanceSubFormModel = {
  salesRevenues: [InitFinanceSubFormEntry],
  directExpenses: [InitFinanceSubFormEntry],
  indirectExpenses: [InitFinanceSubFormEntry],
  equipmentMaterials: [InitFinanceSubFormEntry],
  travelOthers: [InitFinanceSubFormEntry],
  salesRevsTotal: 0,
  directExpsTotal: 0,
  indirectExpsTotal: 0,
  equipMaterialsTotal: 0,
  travelOthersTotal: 0,
  total: 0,
  budgetManagerApprovalStatus: SubFormStatus.SUBMITTED,
  directorApprovalStatus: SubFormStatus.SUBMITTED,
};

// export interface RiskSubFormModel extends SubFormModel {
//   entries: RiskSubFormEntry[];
//   // TODO SOW-351: put form types here
// }

// export interface RiskSubFormEntry {
//   task: string;
//   hazards: string;
//   riskIdentification: string;
//   proposedControlMeasures: string;
//   residualRiskLevel: string;
// }

// export const InitRiskSubFormEntry = {
//   task: "",
//   hazards: "",
//   riskIdentification: "",
//   proposedControlMeasures: "",
//   residualRiskLevel: "",
// };

// export const InitRiskSubFormModel = {
//   entries: [InitRiskSubFormEntry],
// };

export interface RegistrationSubFormModel extends SubFormModel {
  // Dedicated Page Options
  dedicatedPageOption: boolean;
  bannerOption: boolean;
  headerOption: boolean;
  blurbOption: boolean;
  otherContentOption: boolean;
  onlineProductOption: boolean;

  // Dedicated Page Answers
  header: string;
  bannerURL: string;
  blurb: string;
  otherContent: string;
  productName: string;
  price: number;
  productDescription: string;
  thumbnailURL: string;

  // Custom Form Registration
  fullName: boolean;
  email: boolean;
  dob: boolean;
  phoneNumber: boolean;
  emergencyContact: boolean;
  uni: boolean;
  uniYear: boolean;
  degree: boolean;
  church: boolean;
  churchName: boolean;
  diet: boolean;
  source: boolean;
  otherRequests: string;
  otherRequestsOption: boolean;
}

export const InitRegistrationSubFormModel = {
  // Dedicated Page Options
  dedicatedPageOption: false,
  bannerOption: false,
  headerOption: false,
  blurbOption: false,
  otherContentOption: false,
  onlineProductOption: false,

  // Dedicated Page Answers
  header: "",
  bannerURL: "",
  blurb: "",
  otherContent: "",
  productName: "",
  price: 0,
  productDescription: "",
  otherRequests: "",
  thumbnailURL: "",

  // Custom Form Registration
  fullName: false,
  email: false,
  dob: false,
  phoneNumber: false,
  emergencyContact: false,
  uni: false,
  uniYear: false,
  degree: false,
  church: false,
  churchName: false,
  source: false,
  diet: false,
  otherRequestsOption: false,
};
