import {
  Alert,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Snackbar,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { Loading } from "../../../components/Loading";
import { DepartmentsContext, UserContext } from "../../../components/PrivateRoute";
import { TypographyBold } from "../../../components/TypographyBold";
import { DIRECTOR, HEAD_OF_DEPARTMENT, HEAD_OF_DIVISION } from "../../../data/Roles";
import {
  MarketingSubFormModel,
  EventFormModel,
  FinanceSubFormModel,
  SubFormModel,
  SubFormStatus,
  SubFormType,
  RegistrationSubFormModel,
} from "../../../models/EventFormModel";
import { Staff } from "../../../models/Staff";
import {
  approveSubForm,
  getEventForm,
  requestForChangeSubForm,
  submitSubForm,
  updateSubForm,
  deleteSubFormData,
  statusColours,
  getSubFormYear,
} from "../../../services/eventForm";
import { SubFormMarketing } from "./SubFormDesign";
import { SubFormFinance } from "./SubFormFinance";
import { SubFormRequestForChangeDialog } from "./SubFormRequestForChangeDialog";
import { EventFormInformation } from "../EventFormInformation";
import { SubFormRegistration } from "./SubFormRegistration";
import { DATA_AND_IT, EVENTS } from "../../../data/Departments";
import { Path } from "../../../helpers/Path";
import { Variant } from "@mui/material/styles/createTypography";
import { PageNotFound } from "../../PageNotFound";

export const SubFormStatusComponentDisplay = ({ status, variant }: { status?: SubFormStatus; variant: Variant }) => (
  <Typography display="inline" variant={variant} color={status ? statusColours[status] : "gray"}>
    {status === SubFormStatus.APPROVED && "Approved"}
    {status === SubFormStatus.SUBMITTED && "Waiting to be reviewed"}
    {status === SubFormStatus.REQUEST_FOR_CHANGE && "Requested changes"}
    {!status && "Not yet submitted"}
  </Typography>
);

interface SubFormInterface {
  eventForm?: EventFormModel;
  subForm?: SubFormModel;
  submitDialogOpen: boolean;
  approveDialogOpen: boolean;
  requestForChangeDialogOpen: boolean;
  loading: boolean;
}

export const SubForm = () => {
  const user = useContext(UserContext) as Staff;
  const departments = useContext(DepartmentsContext);
  const { type, id, year } = useParams<{ type: SubFormType; id: string; year: string }>();

  const [state, setState] = useState<SubFormInterface>({
    submitDialogOpen: false,
    approveDialogOpen: false,
    requestForChangeDialogOpen: false,
    loading: true,
  });

  const [updating, setUpdating] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const [open, setDeleteDialogOpen] = useState(false);
  const handleDeleteDialogOpen = () => setDeleteDialogOpen(true);
  const handleDeleteDialogClose = () => setDeleteDialogOpen(false);

  const history = useHistory();

  const { eventForm, subForm, submitDialogOpen, approveDialogOpen, requestForChangeDialogOpen, loading } = state;

  useEffect(() => {
    Promise.all([getEventForm(id, year), getSubFormYear(id, type, year) as SubFormModel]).then(
      ([eventForm, subForm]) => {
        setState({ ...state, eventForm, subForm, loading: false });
      }
    );
    // eslint-disable-next-line
  }, [id]);

  const renderCounter = useRef(0);

  useEffect(() => {
    if (subForm) {
      if (renderCounter.current > 0) {
        const delayDebounceFn = setTimeout(() => {
          updateSubForm(id, subForm, type).then(() => {
            setUpdating(false);
            setShowMessage(true);
          });
        }, 1000);
        setUpdating(true);
        setShowMessage(true);
        return () => clearTimeout(delayDebounceFn);
      }
      ++renderCounter.current;
    }
    // eslint-disable-next-line
  }, [subForm]);

  const submitteeIsMe = eventForm?.submittee.id === user.id;
  const submitteeIsMyTeam = eventForm?.submittee.department === user.department;

  const canEdit =
    (submitteeIsMe || submitteeIsMyTeam) &&
    subForm?.status !== SubFormStatus.SUBMITTED &&
    subForm?.status !== SubFormStatus.APPROVED;

  const typeToDepartment = {
    [SubFormType.MARKETING]: departments.MARKETING,
    [SubFormType.FINANCE]: departments.FINANCE,
    // [SubFormType.RISK]: RISK,
    [SubFormType.REGISTRATION]: DATA_AND_IT,
  };

  const isBudgetManager = user.id === departments.BUDGET_MANAGER;

  const isDirector = user.role === DIRECTOR;

  const isRegistrationApprover = user.id === departments.REGISTRATION_REVIEWER;

  const isFinanceHOD = (user as Staff).department === departments.FINANCE && user.role === HEAD_OF_DEPARTMENT;

  const directorNeedsToApprove =
    type === SubFormType.FINANCE && subForm && (subForm as FinanceSubFormModel).total < -5000;

  const budgetManagerAndDirectorApproved =
    type === SubFormType.FINANCE && subForm
      ? directorNeedsToApprove
        ? (subForm as FinanceSubFormModel)?.budgetManagerApprovalStatus === SubFormStatus.APPROVED &&
          (subForm as FinanceSubFormModel)?.directorApprovalStatus === SubFormStatus.APPROVED
        : (subForm as FinanceSubFormModel)?.budgetManagerApprovalStatus === SubFormStatus.APPROVED
      : false;

  const canReview =
    type === SubFormType.FINANCE
      ? (isBudgetManager && (subForm as FinanceSubFormModel)?.budgetManagerApprovalStatus !== SubFormStatus.APPROVED) ||
        (isFinanceHOD && budgetManagerAndDirectorApproved && subForm?.status !== SubFormStatus.APPROVED) ||
        (isDirector &&
          directorNeedsToApprove &&
          (subForm as FinanceSubFormModel)?.directorApprovalStatus !== SubFormStatus.APPROVED)
      : type === SubFormType.REGISTRATION
      ? isRegistrationApprover
      : user.department === typeToDepartment[type];

  const theme = useTheme();

  const setSubFormProperty = (property: keyof SubFormModel, value: any) =>
    subForm && setState({ ...state, subForm: { ...subForm, [property]: value } });

  if (
    !loading &&
    eventForm?.submittee.id !== user.id &&
    eventForm?.submittee.department !== user.department &&
    user.department !== EVENTS &&
    user.role !== DIRECTOR &&
    user.department !== typeToDepartment[type] &&
    (user.department !== DATA_AND_IT || user.role !== HEAD_OF_DEPARTMENT) &&
    user.role !== HEAD_OF_DIVISION
  ) {
    return <PageNotFound />;
  } else {
    return loading ? (
      <Loading />
    ) : (
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={showMessage}
          onClose={() => setShowMessage(false)}
          autoHideDuration={3000}
        >
          <Alert severity={updating ? "warning" : "success"} elevation={6} variant="filled">
            {updating ? "Saving..." : "Saved!"}
          </Alert>
        </Snackbar>
        {subForm && eventForm && (
          <Container maxWidth="lg">
            {canEdit ? (
              <Box
                sx={{
                  display: "inline",
                  float: "right",
                }}
              >
                <Button variant="contained" onClick={handleDeleteDialogOpen}>
                  Reset
                </Button>
                <Dialog open={open} onClose={handleDeleteDialogClose}>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to reset the form? All your changes will be wiped.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleDeleteDialogClose}>Cancel</Button>
                    <Button
                      color="error"
                      onClick={() => {
                        deleteSubFormData(id, type).then(() => {
                          history.push(Path["Event Forms"]);
                        });
                      }}
                      autoFocus
                    >
                      Reset
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            ) : null}
            <Box sx={{ mb: theme.spacing(4) }} />
            <TypographyBold>Event Details:</TypographyBold>
            <EventFormInformation eventForm={eventForm} />
            <Box sx={{ mb: theme.spacing(4) }} />
            <TypographyBold gutterBottom variant="h4">
              {type.charAt(0).toUpperCase() + type.slice(1)} form
            </TypographyBold>
            <Typography display="inline">Status: </Typography>
            <SubFormStatusComponentDisplay status={subForm.status} variant="body1" />
            {canEdit && subForm.status === SubFormStatus.REQUEST_FOR_CHANGE && (
              <Typography>Request for change reason: {subForm.requestForChangeReason}</Typography>
            )}
            {subForm.submittedDate && <Typography>Submitted Date: {subForm.submittedDate}</Typography>}
            {subForm.approvedDate && <Typography>Approved Date: {subForm.approvedDate}</Typography>}
            <Box sx={{ mb: theme.spacing(4) }} />
            {type === SubFormType.MARKETING && (
              <SubFormMarketing
                canEdit={canEdit}
                subForm={subForm as MarketingSubFormModel}
                setSubFormProperty={(property: keyof MarketingSubFormModel, value: any) =>
                  setSubFormProperty(property as keyof SubFormModel, value)
                }
                registrationNumbers={eventForm.registrationGoal}
              />
            )}
            {type === SubFormType.FINANCE && (
              <SubFormFinance
                canEdit={canEdit}
                subForm={subForm as FinanceSubFormModel}
                setSubForm={(subForm) => setState({ ...state, subForm })}
                setSubFormProperty={(property: keyof FinanceSubFormModel, value: any) =>
                  setSubFormProperty(property as keyof SubFormModel, value)
                }
              />
            )}
            {/* {type === SubFormType.RISK && (
              <SubFormRisk
                canEdit={canEdit}
                subForm={subForm as RiskSubFormModel}
                setSubFormProperty={(property: keyof RiskSubFormModel, value: any) =>
                  setSubFormProperty(property as keyof SubFormModel, value)
                }
              />
            )} */}
            {type === SubFormType.REGISTRATION && (
              <SubFormRegistration
                canEdit={canEdit}
                subForm={subForm as RegistrationSubFormModel}
                setSubFormProperty={(property: keyof RegistrationSubFormModel, value: any) =>
                  setSubFormProperty(property as keyof SubFormModel, value)
                }
                eventForm={eventForm}
              />
            )}
            {canEdit && (
              <Button
                variant="contained"
                disabled={updating}
                onClick={() => setState({ ...state, submitDialogOpen: true })}
              >
                Submit
              </Button>
            )}
            {subForm?.status === SubFormStatus.SUBMITTED && canReview && (
              <>
                <Button
                  variant="contained"
                  color="success"
                  disabled={updating}
                  onClick={() => setState({ ...state, approveDialogOpen: true })}
                >
                  Approve
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  disabled={updating}
                  onClick={() => setState({ ...state, requestForChangeDialogOpen: true })}
                >
                  Request for change
                </Button>
              </>
            )}
            <ConfirmationModal
              title={"Submit form?"}
              content={"You will not be able to edit the form once it is submitted."}
              confirmText={"Submit"}
              cancelText={"Cancel"}
              dialogOpen={submitDialogOpen}
              closeDialog={() => setState({ ...state, submitDialogOpen: false })}
              doAction={() =>
                submitSubForm(eventForm, subForm, type).then((status) =>
                  setState({
                    ...state,
                    subForm: { ...subForm, status },
                    eventForm: { ...eventForm, [type + "Status"]: status },
                    submitDialogOpen: false,
                  })
                )
              }
            />
            <ConfirmationModal
              title={"Approve form?"}
              content={"Are you sure you want to approve the form?"}
              confirmText={"Approve"}
              cancelText={"Cancel"}
              dialogOpen={approveDialogOpen}
              closeDialog={() => setState({ ...state, approveDialogOpen: false })}
              doAction={() =>
                type === SubFormType.FINANCE && !isFinanceHOD && subForm
                  ? isBudgetManager || isDirector
                    ? updateSubForm(
                        id,
                        isBudgetManager
                          ? ({
                              budgetManagerApprovalStatus: SubFormStatus.APPROVED,
                              approvedByBudgetManagerTime: new Date(),
                            } as FinanceSubFormModel)
                          : ({
                              directorApprovalStatus: SubFormStatus.APPROVED,
                              approvedByDirectorTime: new Date(),
                            } as FinanceSubFormModel),
                        type
                      ).then(() =>
                        setState({
                          ...state,
                          subForm: isBudgetManager
                            ? ({
                                ...subForm,
                                budgetManagerApprovalStatus: SubFormStatus.APPROVED,
                                approvedByBudgetManagerTime: new Date(),
                              } as FinanceSubFormModel)
                            : ({
                                ...subForm,
                                directorApprovalStatus: SubFormStatus.APPROVED,
                                approvedByDirectorTime: new Date(),
                              } as FinanceSubFormModel),

                          approveDialogOpen: false,
                        })
                      )
                    : {}
                  : approveSubForm({ ...eventForm, [type + "Status"]: SubFormStatus.APPROVED }, type).then(() =>
                      setState({
                        ...state,
                        subForm: { ...subForm, status: SubFormStatus.APPROVED },
                        eventForm: { ...eventForm, [type + "Status"]: SubFormStatus.APPROVED },
                        approveDialogOpen: false,
                      })
                    )
              }
            />
            <SubFormRequestForChangeDialog
              dialogOpen={requestForChangeDialogOpen}
              closeDialog={() => setState({ ...state, requestForChangeDialogOpen: false })}
              requestForChange={(requestForChangeReason) =>
                requestForChangeSubForm(
                  eventForm,
                  requestForChangeReason,
                  type,
                  isBudgetManager ? "budgetManagerApprovalStatus" : isDirector ? "directorApprovalStatus" : undefined
                ).then(() =>
                  setState({
                    ...state,
                    subForm: { ...subForm, status: SubFormStatus.REQUEST_FOR_CHANGE, requestForChangeReason },
                    eventForm: { ...eventForm, [type + "Status"]: SubFormStatus.REQUEST_FOR_CHANGE },
                    requestForChangeDialogOpen: false,
                  })
                )
              }
            />
          </Container>
        )}
      </>
    );
  }
};
