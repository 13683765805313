import {
  Accordion,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Staff } from "../../models/Staff";
import { Request } from "../../models/Request";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { paid, reimbursementPending } from "../../services/reimbursements";
import {
  requestApproved,
  requestApprovedOrCompleted,
  requestCompleted,
  requestDeclined,
  requestStatus,
} from "../../services/requests";
import { Link as ReactRouterLink } from "react-router-dom";
import { Reimbursement } from "../../models/Reimbursement";
import { formatDateTime } from "../../helpers/FormatDate";
import { TypographyBold } from "../../components/TypographyBold";
import { Status } from "../../helpers/Status";
import { RequestDeclined } from "../../models/RequestDeclined";
import { useContext } from "react";
import { DepartmentsContext } from "../../components/PrivateRoute";
import { Path } from "../../helpers/Path";
import { HEAD_OF_DEPARTMENT } from "../../data/Roles";

export const RequestComponent = ({
  request,
  staff,
  pending,
  approve,
  decline,
  cancelRequest,
  submitReimbursement,
  pay,
  pastYear,
}: {
  request: Request;
  staff: Staff;
  pending?: boolean;
  approve?: () => void;
  decline?: () => void;
  cancelRequest?: () => void;
  submitReimbursement?: () => void;
  pay?: () => void;
  pastYear?: boolean;
}) => {
  const theme = useTheme();
  const departments = useContext(DepartmentsContext);
  const myRequest = request.staff?.id === staff.id;
  const canPay = staff.department === departments.FINANCE && staff.role === HEAD_OF_DEPARTMENT;
  const reimbursementSubmitted = (request as Reimbursement).totalReceiptAmount;
  return (
    <Accordion
      sx={{
        margin: theme.spacing(1, 1, 0, 1),
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id="resources">
        <Typography>
          #{request.id} by {request.staff ? request.staff.firstName : ""}
          {requestDeclined(request) && " - DECLINED"}
          {paid(request as Reimbursement) && " - PAID"}
        </Typography>
      </AccordionSummary>
      <Grid xs={12} item>
        <Paper
          sx={{
            padding: theme.spacing(2),
            borderRadius: 0,
          }}
        >
          <Grid container>
            <Grid item xs={5}>
              <Typography display="inline">
                {request.staff ? request.staff.firstName : request.userID} {request.staff ? request.staff.lastName : ""}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Department: {request.department}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              {!requestCompleted(request) && myRequest && !pastYear && (
                <Grid
                  container
                  item
                  justifyContent="flex-end"
                  sx={{
                    mb: theme.spacing(1),
                  }}
                >
                  <Link component="button" color="error" onClick={cancelRequest}>
                    CANCEL REQUEST
                  </Link>
                </Grid>
              )}
              <Grid container item justifyContent="flex-end">
                <Typography variant="caption" color="textSecondary">
                  Submitted {formatDateTime(request.submittedTime)}
                </Typography>
              </Grid>
              {requestApproved(request) && request.approvedTime && (
                <Grid container item justifyContent="flex-end">
                  <Typography variant="caption" color="textSecondary">
                    Approved {formatDateTime(request.approvedTime)}
                  </Typography>
                </Grid>
              )}
              {requestDeclined(request) && request.declinedTime && (
                <Grid container item justifyContent="flex-end">
                  <Typography variant="caption" color="textSecondary">
                    Declined {formatDateTime(request.declinedTime)}
                  </Typography>
                </Grid>
              )}
              {paid(request as Reimbursement) && request.paidTime && (
                <Grid container item justifyContent="flex-end">
                  <Typography variant="caption" color="textSecondary">
                    Paid {formatDateTime(request.paidTime)}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid xs={12} item container direction="column" justifyContent="center" alignItems="center">
              <Typography variant="body2" color="textSecondary">
                Requested Amount
              </Typography>
              <TypographyBold>${request.amount}</TypographyBold>
            </Grid>
            <Grid container item xs={12}>
              <TextField
                disabled
                fullWidth
                label="Description"
                multiline
                sx={{
                  "&:disabled": {
                    color: theme.palette.common.black,
                  },
                }}
                value={request.description}
                variant="outlined"
              />
            </Grid>
            <Grid container item xs={12}>
              {pending && !pastYear && approve ? (
                <Grid xs={6} item direction="column" container justifyContent="center" alignItems="center">
                  <Button variant="outlined" sx={Status(theme).APPROVED} onClick={approve} size="small">
                    Approve
                  </Button>
                  <Button variant="outlined" sx={Status(theme).DECLINED} onClick={decline} size="small">
                    Decline
                  </Button>
                </Grid>
              ) : (
                <Grid xs={6} item direction="column" container justifyContent="center" alignItems="center">
                  <Typography variant="body2" color="textSecondary">
                    Approval Status
                  </Typography>
                  <Typography sx={Status(theme)[requestStatus(request)]}>{requestStatus(request)}</Typography>
                </Grid>
              )}
              {requestApproved(request) && (
                <Grid xs={6} item direction="column" container justifyContent="center" alignItems="center">
                  <Typography variant="body2" color="textSecondary">
                    Reimbursement
                  </Typography>
                  {myRequest && !reimbursementSubmitted && pastYear && (
                    <Typography align="center" sx={Status(theme).PENDING}>
                      RECEIPT NOT SUBMITTED
                    </Typography>
                  )}
                  {myRequest && !reimbursementSubmitted && !pastYear && submitReimbursement && (
                    <Button variant="contained" onClick={submitReimbursement}>
                      SUBMIT
                    </Button>
                  )}
                  {!myRequest && !reimbursementSubmitted && (
                    <Typography align="center" sx={Status(theme).PENDING}>
                      WAITING FOR RECEIPT
                    </Typography>
                  )}
                  {canPay && reimbursementPending(request as Reimbursement) && !pastYear && (
                    <Button variant="outlined" sx={Status(theme).APPROVED} onClick={pay}>
                      PAY
                    </Button>
                  )}
                  {canPay && reimbursementPending(request as Reimbursement) && pastYear && (
                    <Typography align="center" sx={Status(theme).PENDING}>
                      NOT PAID
                    </Typography>
                  )}
                  {!canPay && reimbursementPending(request as Reimbursement) && (
                    <Typography align="center" sx={Status(theme).PENDING}>
                      SUBMITTED BUT NOT PAID
                    </Typography>
                  )}
                  {paid(request as Reimbursement) && <Typography sx={Status(theme).APPROVED}>PAID</Typography>}
                </Grid>
              )}
            </Grid>
            {requestApprovedOrCompleted(request) && reimbursementSubmitted && (
              <>
                <Grid xs={12} item container justifyContent="center" alignItems="center">
                  <TypographyBold>Reimbursement Details</TypographyBold>
                </Grid>
                {(request as Reimbursement).recipients.map((recipient, i) => (
                  <Grid item container xs={12} key={i} sx={{ mb: theme.spacing(1) }}>
                    <Grid xs={12} item container direction="column" justifyContent="center" alignItems="center">
                      <Typography variant="body1">Recipient #{i + 1}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        Account Name
                      </Typography>
                      <Typography>{recipient.accountName}</Typography>
                    </Grid>
                    <Grid xs={4} item direction="column" container justifyContent="center" alignItems="center">
                      <Typography variant="body2" color="textSecondary">
                        Amount
                      </Typography>
                      <TypographyBold>${recipient.receiptAmount}</TypographyBold>
                    </Grid>
                    <Grid xs={4} item direction="column" container justifyContent="center" alignItems="center">
                      <Typography variant="body2" color="textSecondary">
                        BSB
                      </Typography>
                      <Typography>{recipient.bsb}</Typography>
                    </Grid>
                    <Grid xs={4} item direction="column" container justifyContent="center" alignItems="center">
                      <Typography variant="body2" color="textSecondary">
                        Account Number
                      </Typography>
                      <Typography>{recipient.accountNumber}</Typography>
                    </Grid>
                    <Grid xs={12} item container justifyContent="center" alignItems="center">
                      <Typography variant="body2" color="textSecondary">
                        Receipt/Invoice(s)
                      </Typography>
                    </Grid>
                    {recipient.receiptURLs &&
                      recipient.receiptURLs.map((receiptURL, i) => (
                        <Grid
                          xs={12}
                          key={i}
                          item
                          container
                          justifyContent="center"
                          alignItems="center"
                          direction="column"
                        >
                          <Link
                            sx={{
                              cursor: "pointer",
                              wordBreak: "break-all", // Ensures long URLs break properly
                              whiteSpace: "nowrap", // Prevents wrapping if necessary
                              overflow: "hidden", // Prevents overflow
                              textOverflow: "ellipsis", // Adds '...' when text is too long
                              maxWidth: "200px", // Limit width for the ellipsis
                            }}
                            onClick={() => window.open(receiptURL)}
                            underline="hover"
                            variant="body2"
                          >
                            {decodeURIComponent(
                              receiptURL.substring(receiptURL.lastIndexOf("%2F") + 3, receiptURL.lastIndexOf("?"))
                            )}
                          </Link>
                        </Grid>
                      ))}
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                ))}
                <Grid xs={12} item container direction="column" justifyContent="center" alignItems="center">
                  <Typography variant="body2">Total Receipt Amount</Typography>
                  <TypographyBold>${(request as Reimbursement).totalReceiptAmount}</TypographyBold>
                </Grid>
              </>
            )}
          </Grid>
          {(request as RequestDeclined).reason && (
            <Grid
              container
              item
              xs={12}
              sx={{
                mt: theme.spacing(2),
              }}
            >
              <TextField
                disabled
                fullWidth
                label="Declined Reason"
                sx={{
                  "&:disabled": {
                    color: theme.palette.common.black,
                  },
                }}
                value={(request as RequestDeclined).reason}
                variant="outlined"
              />
            </Grid>
          )}
          {paid(request as Reimbursement) && (request as Reimbursement).comment && (
            <Grid
              container
              item
              xs={12}
              sx={{
                mt: theme.spacing(2),
              }}
            >
              <TextField
                disabled
                fullWidth
                label="Reimbursement Comments"
                sx={{
                  "&:disabled": {
                    color: theme.palette.common.black,
                  },
                }}
                value={(request as Reimbursement).comment}
                variant="outlined"
              />
            </Grid>
          )}
          {paid(request as Reimbursement) && (request as Reimbursement).paidAmount && (
            <Grid xs={12} item container direction="column" justifyContent="center" alignItems="center">
              <Typography variant="body2" color="textSecondary">
                Paid Amount
              </Typography>
              <TypographyBold>${(request as Reimbursement).paidAmount}</TypographyBold>
            </Grid>
          )}
          <Divider
            sx={{
              mt: theme.spacing(1),
            }}
          />
          <Grid container direction="column" justifyContent="center" alignItems="center">
            {request.hod && request.department !== departments.FINANCE && (
              <Link
                sx={Status(theme)[request.approvedByHOD]}
                component={ReactRouterLink}
                underline="hover"
                to={`${Path.Profile}/${request.hod.id}`}
              >
                HOD ({request.hod.firstName}): {request.approvedByHOD}
              </Link>
            )}
            {request.finance && (
              <Link
                sx={Status(theme)[request.approvedByFinance]}
                component={ReactRouterLink}
                underline="hover"
                to={`${Path.Profile}/${request.finance.id}`}
              >
                Budget Manager ({request.finance.firstName}): {request.approvedByFinance}
              </Link>
            )}
            {request.director && request.approvedByDirector !== undefined && (
              <Link
                sx={Status(theme)[request.approvedByDirector]}
                component={ReactRouterLink}
                underline="hover"
                to={`${Path.Profile}/${request.director.id}`}
              >
                Director ({request.director.firstName}): {request.approvedByDirector}
              </Link>
            )}
            {request.financeHead && (
              <Link
                sx={Status(theme)[request.approvedByFinanceHead]}
                component={ReactRouterLink}
                underline="hover"
                to={`${Path.Profile}/${request.financeHead.id}`}
              >
                Finance Head ({request.financeHead.firstName}): {request.approvedByFinanceHead}
              </Link>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Accordion>
  );
};
