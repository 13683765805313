export const HUMAN_RESOURCES = "Human Resources";
export const TRAINING_AND_DEVELOPMENT = "Training and Development";
export const PEOPLE_AND_CULTURE = "People and Culture";
export const CAMPUS = "Campus";
export const FINANCE = "Finance";
export const EVENTS = "Events";
export const MARKETING = "Marketing";
export const CHAPLAINCY = "Chaplaincy";
export const MISSIONS = "Missions";
export const DATA_AND_IT = "Data and IT";
export const COMPLIANCE = "Compliance";
