import { Button, Typography } from "@mui/material";
import { User as AuthUser, unlink } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Loading } from "../components/Loading";
import { MessageSnackbar } from "../components/MessageSnackbar";
import { MessageState } from "../models/MessageState";
import { User } from "../models/User";
import { functions } from "../services/firebase";
import { getListOfUsers } from "../services/users";
import { Review360HelpDialog } from "./360-reviews/360-review/Review360HelpDialog";
import { Term, YearAndTerm } from "../models/Review360Model";
import { InitYearAndTerm } from "../data/Review360Data";
import { getPastYearAndTermsAll, getReviewsData } from "../services/review360";
import { RolesContext } from "../components/PrivateRoute";
import { moveEventForms } from "../services/admin";

interface AdminInterface {
  loading: boolean;
  alert: MessageState;
  openHelpDialog: boolean;
  postDialogOpen: boolean;
  users: User[];
  selectedYearAndTerm: YearAndTerm;
  pastYearAndTerms: YearAndTerm[];
  calculatingResults: boolean;
  calculatedMetrics: boolean;
}

export const Admin = ({ currentUser }: { currentUser: AuthUser }) => {
  // const theme = useTheme();
  const [state, setState] = useState<AdminInterface>({
    loading: true,
    alert: {
      showMessage: false,
      message: "",
      severity: "success",
    },
    users: [],
    openHelpDialog: false,
    postDialogOpen: false,
    selectedYearAndTerm: InitYearAndTerm,
    pastYearAndTerms: [],
    calculatingResults: false,
    calculatedMetrics: false,
  });

  useEffect(() => {
    getListOfUsers().then((users) =>
      getReviewsData().then((reviewsData) =>
        getPastYearAndTermsAll(reviewsData).then((pastYearAndTerms) =>
          setState({
            ...state,
            users,
            pastYearAndTerms,
            loading: false,
          })
        )
      )
    );

    // eslint-disable-next-line
  }, []);

  const yearAndTermToString = (yearAndTerm: YearAndTerm): string => `${yearAndTerm.year} ${yearAndTerm.term}`;

  const convertYearAndTermBack = (yearAndTermString: string): YearAndTerm => ({
    year: Number(yearAndTermString.split(" ")[0]),
    term: yearAndTermString.split(" ")[1] as Term,
  });

  const roles = useContext(RolesContext);

  const {
    loading,
    users,
    openHelpDialog,
    alert,
    selectedYearAndTerm,
    pastYearAndTerms,
    calculatingResults,
    calculatedMetrics,
  } = state;

  const headers = [
    { label: "ID", key: "id" },
    { label: "Email", key: "email" },
    { label: "Role", key: "role" },
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Phone Number", key: "phoneNumber" },
    { label: "Department", key: "department" },
    { label: "Local Church", key: "localChurch" },
    { label: "University", key: "university" },
    { label: "University Degree", key: "universityDegree" },
    { label: "Photo URL", key: "photoURL" },
  ];

  const unLinkWithMicrosoft = () => unlink(currentUser, "microsoft.com");

  return loading ? (
    <Loading />
  ) : (
    <>
      <Button variant="contained" onClick={() => setState({ ...state, postDialogOpen: true })}>
        Event
      </Button>
      <Review360HelpDialog
        dialogOpen={openHelpDialog}
        closeDialog={() => setState({ ...state, openHelpDialog: false })}
      />
      <Button variant="contained" onClick={() => setState({ ...state, openHelpDialog: true })}>
        Help
      </Button>
      <MessageSnackbar messageState={alert} setMessageState={(alert) => setState({ ...state, alert })} />
      <Typography>Add your first code change below here!</Typography>
      <Typography>Daniel's first code change</Typography>
      <Typography>Dominic's first code change</Typography>
      <Typography>Owen's first code change</Typography>
      <Typography>Daniel L's first code change</Typography>
      <Typography>Add your first code change above here!</Typography>
      <Button
        variant="contained"
        onClick={() => httpsCallable(functions, "test")().then((result) => console.log(result))}
      >
        test
      </Button>
      <Button variant="contained" onClick={() => unLinkWithMicrosoft()}>
        unlink with Microsoft
      </Button>
      <Button variant="contained" onClick={() => moveEventForms()}>
        Move event forms
      </Button>
      {/* <Select
        required
        fullWidth
        placeholder="Year and Term"
        labelId="yearAndTerm-label"
        id="yearAndTerm"
        onChange={(event) =>
          event.target.value
            ? setState({ ...state, selectedYearAndTerm: convertYearAndTermBack(event.target.value) })
            : null
        }
        name="yearAndTerm"
        value={selectedYearAndTerm ? yearAndTermToString(selectedYearAndTerm) : "Select"}
        variant="outlined"
      >
        <MenuItem disabled value="Select">
          None
        </MenuItem>
        {pastYearAndTerms.map((pastYearAndTerm, i) => (
          <MenuItem key={i} value={yearAndTermToString(pastYearAndTerm)}>
            {yearAndTermToString(pastYearAndTerm)}
          </MenuItem>
        ))}
      </Select>
      {calculatingResults ? (
        <CircularProgress size={30} />
      ) : (
        <Button
          variant="contained"
          onClick={() => {
            setState({ ...state, calculatingResults: true });
            calculateResultsAdmin(selectedYearAndTerm).then(() => setState({ ...state, calculatingResults: false }));
          }}
        >
          Calculate Results
        </Button>
      )}
      {calculatedMetrics ? (
        <CircularProgress size={30} />
      ) : (
        <Button
          variant="contained"
          onClick={() => {
            setState({ ...state, calculatedMetrics: true });
            getPercentOfOutgoingLeadersByYear(roles, "All Leaders").then(() =>
              getPercentOfOutgoingLeadersByYear(roles, "Campus Leaders").then(() =>
                getPercentOfOutgoingLeadersByYear(roles, "Staff").then(() =>
                  getAverageYearsServedByOutgoingLeadersByYear(roles, "All Leaders").then(() =>
                    getAverageYearsServedByOutgoingLeadersByYear(roles, "Campus Leaders").then(() =>
                      getAverageYearsServedByOutgoingLeadersByYear(roles, "Staff").then(() =>
                        setState({
                          ...state,
                          loading: false,
                        })
                      )
                    )
                  )
                )
              )
            );
          }}
        >
          Store Retention Rate data
        </Button>
      )} */}
      <CSVLink filename={"Users.csv"} data={users} headers={headers}>
        Export Users to CSV
      </CSVLink>
    </>
  );
};
