import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { getEventForm, getSubFormYear } from "../../services/eventForm";
import { DepartmentsContext, UserContext } from "../../components/PrivateRoute";
import { Staff } from "../../models/Staff";
import {
  EventFormModel,
  FinanceSubFormModel,
  MarketingSubFormModel,
  RegistrationSubFormModel,
  SubFormType,
} from "../../models/EventFormModel";
import { DATA_AND_IT, EVENTS } from "../../data/Departments";
import { formatDateTime } from "../../helpers/FormatDate";
import { PageNotFound } from "../PageNotFound";
import { PDFDownloadLink, Document, StyleSheet, Page, Text, View } from "@react-pdf/renderer";
import { Grid } from "@mui/material";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subHeading: {
    fontSize: 16,
    fontWeight: "heavy",
    color: "grey",
    margin: 2,
  },
  subHeadingItalic: {
    fontSize: 16,
    fontWeight: "heavy",
    color: "grey",
    margin: 2,
    fontStyle: "italic",
  },
  subWithoutMargin: {
    margin: 2,
    fontSize: 14,
    textAlign: "justify",
  },
  sub: {
    margin: 2,
    fontSize: 14,
    marginBottom: 12,
    textAlign: "justify",
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  tableHeader: {
    fontSize: 13,
    flexDirection: "row",
    marginBottom: 12,
    fontWeight: "heavy",
    justifyContent: "space-between",
  },
  tableRow: {
    fontSize: 12,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 12,
  },
  totalRow: {
    fontSize: 12,
    textAlign: "justify",
    fontWeight: "heavy",
    marginBottom: 12,
  },
  rowColumn1: {
    flex: 2,
  },
  rowColumn2: {
    flex: 2,
  },
  rowColumn5: {
    flex: 5,
  },
});

const MyDoc = ({
  event,
  marketing,
  finance,
  // risk,
  registration,
}: {
  event: EventFormModel;
  marketing: MarketingSubFormModel;
  finance: FinanceSubFormModel;
  // risk: RiskSubFormModel;
  registration: RegistrationSubFormModel;
}) => (
  <Document>
    <Page style={styles.body}>
      <Text style={styles.title}>{event.name}</Text>
      <Text style={styles.author}>
        {event.submittee.firstName} {event.submittee.lastName} - {event.submittee.department}
      </Text>
      <Text style={styles.subHeading}>Submitted Time</Text>
      <Text style={styles.sub}>{formatDateTime(event.submittedTime)}</Text>
      <Text style={styles.subHeading}>Last Updated</Text>
      <Text style={styles.sub}>{formatDateTime(event.updatedTime)}</Text>
      <Text style={styles.subHeading}>Event Start Time</Text>
      <Text style={styles.sub}>{formatDateTime(event.eventStartTime)}</Text>
      <Text style={styles.subHeading}>Event End Time</Text>
      <Text style={styles.sub}>{formatDateTime(event.eventEndTime)}</Text>
      <Text style={styles.subHeading}>Event Location</Text>
      <Text style={styles.sub}>{event.location}</Text>
      <Text style={styles.subHeading}>Event Purpose</Text>
      <Text style={styles.sub}>{event.purpose}</Text>
      <Text style={styles.subHeading}>Event Goals</Text>
      <Text style={styles.sub}>{event.goals}</Text>
      <Text style={styles.subHeading}>Event Theme</Text>
      <Text style={styles.sub}>{event.theme}</Text>
      <Text style={styles.subHeading}>Registration Goal</Text>
      <Text style={styles.sub}>{event.registrationGoal}</Text>
      <Text style={styles.subHeading}>Target Audience</Text>
      <Text style={styles.sub}>{event.audience}</Text>
      <Text style={styles.subHeading}>Registrations To Open</Text>
      <Text style={styles.sub}>{formatDateTime(event.registrationOpenTime)}</Text>
      <Text style={styles.subHeading}>Registrations To Close</Text>
      <Text style={styles.sub}>{formatDateTime(event.registrationCloseTime)}</Text>
      <Text style={styles.subHeading}>Notes</Text>
      <Text style={styles.sub}>{event.notes}</Text>
    </Page>

    <Page style={styles.body}>
      <Text style={styles.title}>Marketing form</Text>
      <Text style={styles.subHeading}>Status</Text>
      <Text style={styles.sub}>{marketing.status}</Text>
      <Text style={styles.subtitle}>Design</Text>

      <Text style={styles.subHeading}>What is the key message you would like to send out/for us to reflect?</Text>
      <Text style={styles.sub}>{marketing.keyMessage}</Text>

      <Text style={styles.subHeading}>
        Is there a theme/colour palette/visual preference that you would like us to work with? (Write n/a if you would
        like the design team to decide)
      </Text>
      <Text style={styles.sub}>{marketing.theme}</Text>

      <Text style={styles.subHeading}>What would you like us to design?</Text>

      <Text style={styles.subHeadingItalic}>Printed</Text>
      {marketing.brochures && (
        <>
          <Text style={styles.subWithoutMargin}>Brochures</Text>
          <Text style={styles.sub}>{marketing.brochuresSpecifications}</Text>
        </>
      )}
      {marketing.flyers && (
        <>
          <Text style={styles.subWithoutMargin}>Flyers</Text>
          <Text style={styles.sub}>{marketing.flyersSpecifications}</Text>
        </>
      )}
      {marketing.invitations && (
        <>
          <Text style={styles.subWithoutMargin}>Invitations</Text>
          <Text style={styles.sub}>{marketing.invitationsSpecifications}</Text>
        </>
      )}
      {marketing.signage && (
        <>
          <Text style={styles.subWithoutMargin}>Signage</Text>
          <Text style={styles.sub}>{marketing.signageSpecifications}</Text>
        </>
      )}
      {marketing.businessCard && (
        <>
          <Text style={styles.subWithoutMargin}>Business Card</Text>
          <Text style={styles.sub}>{marketing.businessCardSpecifications}</Text>
        </>
      )}
      {marketing.pamphlet && (
        <>
          <Text style={styles.subWithoutMargin}>Pamphlet</Text>
          <Text style={styles.sub}>{marketing.pamphletSpecifications}</Text>
        </>
      )}
      {marketing.poster && (
        <>
          <Text style={styles.subWithoutMargin}>Poster</Text>
          <Text style={styles.sub}>{marketing.posterSpecifications}</Text>
        </>
      )}
      {marketing.postcard && (
        <>
          <Text style={styles.subWithoutMargin}>Postcard</Text>
          <Text style={styles.sub}>{marketing.postcardSpecifications}</Text>
        </>
      )}
      {marketing.merchandise && (
        <>
          <Text style={styles.subWithoutMargin}>Merchandise</Text>
          <Text style={styles.sub}>{marketing.merchandiseSpecifications}</Text>
        </>
      )}
      {marketing.otherPrinted && (
        <>
          <Text style={styles.subWithoutMargin}>Other</Text>
          <Text style={styles.sub}>{marketing.otherPrintedSpecifications}</Text>
        </>
      )}

      <Text style={styles.subHeadingItalic}>Digital</Text>
      {marketing.logo && (
        <>
          <Text style={styles.subWithoutMargin}>Logo</Text>
        </>
      )}
      {marketing.powerpoint && (
        <>
          <Text style={styles.subWithoutMargin}>Powerpoint Presentation</Text>
        </>
      )}
      {marketing.letterhead && (
        <>
          <Text style={styles.subWithoutMargin}>Letterhead</Text>
        </>
      )}
      {marketing.facebookEventBanner && (
        <>
          <Text style={styles.subWithoutMargin}>Facebook Event Banner</Text>
        </>
      )}
      {marketing.instagramPost && (
        <>
          <Text style={styles.subWithoutMargin}>Instagram Post</Text>
        </>
      )}
      {marketing.websiteBanner && (
        <>
          <Text style={styles.subWithoutMargin}>Website Banner</Text>
        </>
      )}
      {marketing.promoVideo && (
        <>
          <Text style={styles.subWithoutMargin}>Promotion Video</Text>
          <Text style={styles.sub}>{marketing.promoVideoSpecifications}</Text>
        </>
      )}
      {marketing.recapVideo && (
        <>
          <Text style={styles.subWithoutMargin}>Recap Video</Text>
          <Text style={styles.sub}>{marketing.recapVideoSpecifications}</Text>
        </>
      )}
      {marketing.otherDigital && (
        <>
          <Text style={styles.subWithoutMargin}>Other Digital Materials</Text>
          <Text style={styles.sub}>{marketing.otherDigitalSpecifications}</Text>
        </>
      )}

      <Text style={styles.subHeading}>Would you like us to run the design by you before finalising?</Text>
      <Text style={styles.sub}>{marketing.designNeedsApproval}</Text>

      <Text style={styles.subHeadingItalic}>Promotion</Text>
      <Text style={styles.subHeading}>What do you require on the day?</Text>
      {marketing.photography && (
        <>
          <Text style={styles.subWithoutMargin}>Photography</Text>
          <Text style={styles.sub}>{marketing.photography}</Text>
        </>
      )}
      {marketing.videography && (
        <>
          <Text style={styles.subWithoutMargin}>Videography</Text>
          <Text style={styles.sub}>{marketing.videography}</Text>
        </>
      )}
      {marketing.instagram && (
        <>
          <Text style={styles.subWithoutMargin}>Instagram Stories</Text>
          <Text style={styles.sub}>{marketing.instagram}</Text>
        </>
      )}
      {marketing.bibleVerse && (
        <>
          <Text style={styles.subWithoutMargin}>Bible Verse</Text>
          <Text style={styles.sub}>{marketing.bibleVerse}</Text>
        </>
      )}
      {marketing.themePurpose && (
        <>
          <Text style={styles.subWithoutMargin}>Theme/ Purpose</Text>
          <Text style={styles.sub}>{marketing.themePurpose}</Text>
        </>
      )}
      {marketing.saveTheDate && (
        <>
          <Text style={styles.subWithoutMargin}>Save The Date</Text>
          <Text style={styles.sub}>{marketing.saveTheDate}</Text>
        </>
      )}
      {marketing.throwbackPhotos && (
        <>
          <Text style={styles.subWithoutMargin}>Throwback Photos</Text>
          <Text style={styles.sub}>{marketing.throwbackPhotos}</Text>
        </>
      )}
      {marketing.directorsAddress && (
        <>
          <Text style={styles.subWithoutMargin}>Director's Address</Text>
          <Text style={styles.sub}>{marketing.directorsAddress}</Text>
        </>
      )}
      {marketing.registrationCountdown && (
        <>
          <Text style={styles.subWithoutMargin}>Registration Countdown</Text>
          <Text style={styles.sub}>{marketing.registrationCountdown}</Text>
        </>
      )}
      {marketing.prizeInventive && (
        <>
          <Text style={styles.subWithoutMargin}>Prize/ Incentive</Text>
          <Text style={styles.sub}>{marketing.prizeInventive}</Text>
        </>
      )}
      {marketing.sponsorships && (
        <>
          <Text style={styles.subWithoutMargin}>Sponsorships</Text>
          <Text style={styles.sub}>{marketing.sponsorships}</Text>
        </>
      )}
      {marketing.rulesFaqs && (
        <>
          <Text style={styles.subWithoutMargin}>Rules/ FAQs</Text>
          <Text style={styles.sub}>{marketing.rulesFaqs}</Text>
        </>
      )}
      {marketing.merchandisePromo && (
        <>
          <Text style={styles.subWithoutMargin}>Merchandise</Text>
          <Text style={styles.sub}>{marketing.merchandisePromo}</Text>
        </>
      )}
      {marketing.paidAds && (
        <>
          <Text style={styles.subWithoutMargin}>Paid Ads</Text>
          <Text style={styles.sub}>{marketing.paidAds}</Text>
        </>
      )}
      {marketing.campusPromotion && (
        <>
          <Text style={styles.subWithoutMargin}>Campus Promotion</Text>
          <Text style={styles.sub}>{marketing.campusPromotion}</Text>
        </>
      )}
      {marketing.finalRemindersInfo && (
        <>
          <Text style={styles.subWithoutMargin}>Final Reminders/ Info</Text>
          <Text style={styles.sub}>{marketing.finalRemindersInfo}</Text>
        </>
      )}
      {marketing.eventPhotos && (
        <>
          <Text style={styles.subWithoutMargin}>Event Photos (Post-event)</Text>
          <Text style={styles.sub}>{marketing.eventPhotos}</Text>
        </>
      )}
      {marketing.otherPromotion && (
        <>
          <Text style={styles.subWithoutMargin}>Other Promotion Materials</Text>
          <Text style={styles.sub}>{marketing.otherPromotion}</Text>
          <Text style={styles.sub}>{marketing.otherPromotionSpecifications}</Text>
        </>
      )}

      <Text style={styles.subHeading}>When would you like promo to start?</Text>
      <Text style={styles.sub}>{marketing.promoStart}</Text>

      <Text style={styles.subHeading}>Please provide all important information you want us to include in posts</Text>
      <Text style={styles.sub}>{marketing.importantPromotionInformation}</Text>

      <Text style={styles.subHeading}>Do you have any other information you would like to include?</Text>
      <Text style={styles.sub}>{marketing.otherPromotionInformation}</Text>
    </Page>

    <Page style={styles.body}>
      <Text style={styles.title}>Finance form</Text>
      <Text style={styles.subHeading}>Status</Text>
      <Text style={styles.sub}>{finance.status}</Text>
      <Text style={styles.subtitle}>Budget Breakdown</Text>

      <Text style={styles.subHeading}>1) SALES/REVENUE</Text>
      <View style={styles.tableHeader}>
        <Text style={styles.rowColumn1}>Type</Text>
        <Text style={styles.rowColumn2}>Unit Price</Text>
        <Text style={styles.rowColumn2}>Quantity</Text>
        <Text style={styles.rowColumn2}>Total</Text>
        <Text style={styles.rowColumn5}>Justification</Text>
      </View>

      {finance.salesRevenues.map((entry, index) => (
        <View key={index} style={styles.tableRow}>
          <Text style={styles.rowColumn1}>{entry.type}</Text>
          <Text style={styles.rowColumn2}>{entry.unitPrice}</Text>
          <Text style={styles.rowColumn2}>{entry.quantity}</Text>
          <Text style={styles.rowColumn2}>{entry.total}</Text>
          <Text style={styles.rowColumn5}>{entry.justification}</Text>
        </View>
      ))}
      <Text style={styles.totalRow}>Sales/Revenue Total: {finance.salesRevsTotal}</Text>

      <Text style={styles.subHeading}>2) DIRECT EXPENSES</Text>
      <View style={styles.tableHeader}>
        <Text style={styles.rowColumn1}>Type</Text>
        <Text style={styles.rowColumn2}>Unit Price</Text>
        <Text style={styles.rowColumn2}>Quantity</Text>
        <Text style={styles.rowColumn2}>Total</Text>
        <Text style={styles.rowColumn5}>Justification</Text>
      </View>

      {finance.directExpenses.map((entry, index) => (
        <View key={index} style={styles.tableRow}>
          <Text style={styles.rowColumn1}>{entry.type}</Text>
          <Text style={styles.rowColumn2}>{entry.unitPrice}</Text>
          <Text style={styles.rowColumn2}>{entry.quantity}</Text>
          <Text style={styles.rowColumn2}>{entry.total}</Text>
          <Text style={styles.rowColumn5}>{entry.justification}</Text>
        </View>
      ))}
      <Text style={styles.totalRow}>Direct Expenses Total: {finance.directExpsTotal}</Text>

      <Text style={styles.subHeading}>3) INDIRECT EXPENSES</Text>
      <View style={styles.tableHeader}>
        <Text style={styles.rowColumn1}>Type</Text>
        <Text style={styles.rowColumn2}>Unit Price</Text>
        <Text style={styles.rowColumn2}>Quantity</Text>
        <Text style={styles.rowColumn2}>Total</Text>
        <Text style={styles.rowColumn5}>Justification</Text>
      </View>

      {finance.indirectExpenses.map((entry, index) => (
        <View key={index} style={styles.tableRow}>
          <Text style={styles.rowColumn1}>{entry.type}</Text>
          <Text style={styles.rowColumn2}>{entry.unitPrice}</Text>
          <Text style={styles.rowColumn2}>{entry.quantity}</Text>
          <Text style={styles.rowColumn2}>{entry.total}</Text>
          <Text style={styles.rowColumn5}>{entry.justification}</Text>
        </View>
      ))}
      <Text style={styles.totalRow}>Indirect Expenses Total: {finance.indirectExpsTotal}</Text>

      <Text style={styles.subHeading}>4) EQUIPMENT AND MATERIALS</Text>
      <View style={styles.tableHeader}>
        <Text style={styles.rowColumn1}>Type</Text>
        <Text style={styles.rowColumn2}>Unit Price</Text>
        <Text style={styles.rowColumn2}>Quantity</Text>
        <Text style={styles.rowColumn2}>Total</Text>
        <Text style={styles.rowColumn5}>Justification</Text>
      </View>

      {finance.indirectExpenses.map((entry, index) => (
        <View key={index} style={styles.tableRow}>
          <Text style={styles.rowColumn1}>{entry.type}</Text>
          <Text style={styles.rowColumn2}>{entry.unitPrice}</Text>
          <Text style={styles.rowColumn2}>{entry.quantity}</Text>
          <Text style={styles.rowColumn2}>{entry.total}</Text>
          <Text style={styles.rowColumn5}>{entry.justification}</Text>
        </View>
      ))}
      <Text style={styles.totalRow}>Equipment and Materials Total: {finance.equipMaterialsTotal}</Text>

      <Text style={styles.subHeading}>5) TRAVEL AND OTHER EXPENSES</Text>
      <View style={styles.tableHeader}>
        <Text style={styles.rowColumn1}>Type</Text>
        <Text style={styles.rowColumn2}>Unit Price</Text>
        <Text style={styles.rowColumn2}>Quantity</Text>
        <Text style={styles.rowColumn2}>Total</Text>
        <Text style={styles.rowColumn5}>Justification</Text>
      </View>

      {finance.indirectExpenses.map((entry, index) => (
        <View key={index} style={styles.tableRow}>
          <Text style={styles.rowColumn1}>{entry.type}</Text>
          <Text style={styles.rowColumn2}>{entry.unitPrice}</Text>
          <Text style={styles.rowColumn2}>{entry.quantity}</Text>
          <Text style={styles.rowColumn2}>{entry.total}</Text>
          <Text style={styles.rowColumn5}>{entry.justification}</Text>
        </View>
      ))}
      <Text style={styles.totalRow}>Travel and Other Expenses Total: {finance.travelOthersTotal}</Text>

      <Text style={styles.sub}>Total: {finance.total}</Text>
    </Page>

    {/* <Page style={styles.body}>
      <Text style={styles.title}>Risk form</Text>
      <Text style={styles.subHeading}>Status</Text>
      <Text style={styles.sub}>{risk.status}</Text>
      <Text style={styles.subtitle}></Text>

      {risk.entries.map((entry, index) => (
        <View key={index} style={styles.sub}>
          <Text style={styles.subHeading}>Task Name</Text>
          <Text style={styles.sub}>{entry.task}</Text>

          <Text style={styles.subHeading}>Hazards</Text>
          <Text style={styles.sub}>{entry.hazards}</Text>

          <Text style={styles.subHeading}>Risk Identification</Text>
          <Text style={styles.sub}>{entry.riskIdentification}</Text>

          <Text style={styles.subHeading}>Proposed Control Measures</Text>
          <Text style={styles.sub}>{entry.proposedControlMeasures}</Text>

          <Text style={styles.subHeading}>Residual Risk Level</Text>
          <Text style={styles.sub}>{entry.residualRiskLevel}</Text>
        </View>
      ))}
    </Page> */}

    <Page style={styles.body}>
      <Text style={styles.title}>Registration form</Text>
      <Text style={styles.subHeading}>Status</Text>
      <Text style={styles.sub}>{registration.status}</Text>
      <Text style={styles.subtitle}></Text>

      <Text style={styles.subHeadingItalic}>Dedicated Page Customisation</Text>
      <Text style={styles.subHeading}>Would you like to have a dedicated page on the SOW website?</Text>
      <Text style={styles.subWithoutMargin}>{registration.dedicatedPageOption}</Text>

      <Text style={styles.subHeading}>Selected Components</Text>
      {registration.bannerOption && (
        <>
          <Text style={styles.subWithoutMargin}>Banner</Text>
          <Text style={styles.sub}>{registration.bannerURL}</Text>
        </>
      )}
      {registration.headerOption && (
        <>
          <Text style={styles.subWithoutMargin}>Header</Text>
          <Text style={styles.sub}>{registration.header}</Text>
        </>
      )}
      {registration.blurbOption && (
        <>
          <Text style={styles.subWithoutMargin}>Dedicated Page Content Text</Text>
          <Text style={styles.sub}>{registration.blurb}</Text>
        </>
      )}
      {registration.otherContentOption && (
        <>
          <Text style={styles.subWithoutMargin}>Other Content</Text>
          <Text style={styles.sub}>{registration.otherContent}</Text>
        </>
      )}

      <Text style={styles.subHeadingItalic}>Registration Customisation</Text>
      <Text style={styles.subHeading}>Would you like to have an online product for your event?</Text>
      <Text style={styles.subWithoutMargin}>{registration.onlineProductOption}</Text>
      {registration.onlineProductOption && (
        <>
          <Text style={styles.subWithoutMargin}>Name of Product</Text>
          <Text style={styles.sub}>{registration.productName}</Text>

          <Text style={styles.subWithoutMargin}>Price of Product</Text>
          <Text style={styles.sub}>{registration.price}</Text>

          <Text style={styles.subWithoutMargin}>Description for Product</Text>
          <Text style={styles.sub}>{registration.productDescription}</Text>

          <Text style={styles.subWithoutMargin}>Thumnail Image URL</Text>
          <Text style={styles.sub}>{registration.thumbnailURL}</Text>

          <Text style={styles.subWithoutMargin}>Thumnail Image URL</Text>
          <Text style={styles.sub}>{registration.thumbnailURL}</Text>

          {registration.onlineProductOption && (
            <>
              <Text style={styles.subHeading}>Information wanted to be captured in your registration form</Text>
              {registration.fullName && (
                <>
                  <Text style={styles.sub}>Full Name</Text>
                </>
              )}
              {registration.email && (
                <>
                  <Text style={styles.sub}>Email Address</Text>
                </>
              )}
              {registration.dob && (
                <>
                  <Text style={styles.sub}>Date of Birth</Text>
                </>
              )}
              {registration.phoneNumber && (
                <>
                  <Text style={styles.sub}>Phone Number</Text>
                </>
              )}
              {registration.emergencyContact && (
                <>
                  <Text style={styles.sub}>Emergency Contact (Name, Relationship, Phone Number)</Text>
                </>
              )}
              {registration.uni && (
                <>
                  <Text style={styles.sub}>University/Institution</Text>
                </>
              )}
              {registration.uniYear && (
                <>
                  <Text style={styles.sub}>University Year</Text>
                </>
              )}
              {registration.degree && (
                <>
                  <Text style={styles.sub}>Degree/Major</Text>
                </>
              )}
              {registration.church && (
                <>
                  <Text style={styles.sub}>Attending a Church</Text>
                </>
              )}
              {registration.churchName && (
                <>
                  <Text style={styles.sub}>Church Name</Text>
                </>
              )}
              {registration.diet && (
                <>
                  <Text style={styles.sub}>Do you have any dietary requirements?</Text>
                </>
              )}
              {registration.source && (
                <>
                  <Text style={styles.sub}>How did you hear about us?</Text>
                </>
              )}
              {registration.otherRequestsOption && (
                <>
                  <Text style={styles.sub}>Other Requests</Text>
                  <Text style={styles.sub}>{registration.otherRequests}</Text>
                </>
              )}
            </>
          )}
        </>
      )}
    </Page>
  </Document>
);

export interface EventFormDownloadInterface {
  loading: boolean;
  finance?: FinanceSubFormModel;
  marketing?: MarketingSubFormModel;
  registration?: RegistrationSubFormModel;
  event?: EventFormModel;
}

export const EventFormDownload = () => {
  const user = useContext(UserContext) as Staff;
  const departments = useContext(DepartmentsContext);
  const { year, id } = useParams<{ year: string; id: string }>();

  const [state, setState] = useState<EventFormDownloadInterface>({
    loading: true,
  });

  const { loading, event, finance, marketing, registration } = state;

  useEffect(() => {
    const event = getEventForm(id, year);
    const finance = getSubFormYear(id, SubFormType.FINANCE, year) as Promise<FinanceSubFormModel>;
    const marketing = getSubFormYear(id, SubFormType.MARKETING, year) as Promise<MarketingSubFormModel>;
    // const risk = getSubFormYear(id, SubFormType.RISK, year) as Promise<RiskSubFormModel>;
    const registration = getSubFormYear(id, SubFormType.REGISTRATION, year) as Promise<RegistrationSubFormModel>;
    Promise.all([event, finance, marketing, registration]).then(([event, finance, marketing, registration]) =>
      setState({
        ...state,
        event,
        finance,
        marketing,
        registration,
        loading: false,
      })
    );
    // eslint-disable-next-line
  }, []);

  if (
    !loading &&
    event?.submittee.id !== user.id &&
    event?.submittee.department !== user.department &&
    user.department !== EVENTS &&
    user.department !== departments.MARKETING &&
    user.department !== departments.FINANCE &&
    // user.department !== COMPLIANCE &&
    user.department !== DATA_AND_IT
  ) {
    return <PageNotFound />;
  } else {
    return loading ? (
      <Loading />
    ) : event && finance && marketing && registration ? (
      <Grid container justifyContent="center">
        <PDFDownloadLink
          style={styles.title}
          document={<MyDoc event={event} finance={finance} marketing={marketing} registration={registration} />}
          fileName={event.name + ".pdf"}
        >
          {({ blob, url, loading, error }) => (loading ? "Loading PDF..." : "Download PDF")}
        </PDFDownloadLink>
      </Grid>
    ) : (
      <PageNotFound />
    );
  }
};
