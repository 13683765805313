import { ExpandLess, ExpandMore } from "@mui/icons-material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import SettingsIcon from "@mui/icons-material/Settings";
import BrushIcon from "@mui/icons-material/Brush";
import CampaignIcon from "@mui/icons-material/Campaign";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
// import BarChartIcon from "@mui/icons-material/BarChart";
import EventIcon from "@mui/icons-material/Event";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import MenuIcon from "@mui/icons-material/Menu";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import RateReviewIcon from "@mui/icons-material/RateReview";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import {
  AppBar,
  Button,
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  SvgIcon,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { styled, useTheme } from "@mui/material/styles";
import { ReactNode, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  DATA_AND_IT,
  FINANCE,
  HUMAN_RESOURCES,
  PEOPLE_AND_CULTURE,
  TRAINING_AND_DEVELOPMENT,
} from "../data/Departments";
import { DIRECTOR, STAFF } from "../data/Roles";
import { Path } from "../helpers/Path";
import { atLeast } from "../helpers/Role";
import { ReactComponent as CollapseButton } from "../images/CollapseButton.svg";
import { ReactComponent as ExpandButton } from "../images/ExpandButton.svg";
import sowLogo from "../images/sowLogo.png";
import theShedAndSowLogo from "../images/theShedAndSowLogo.png";
import { Staff } from "../models/Staff";
import { logout } from "../services/auth";
import { DrawerNavbar } from "./DrawerNavbar";
import { RolesContext, UserContext } from "./PrivateRoute";
import { TypographyBold } from "./TypographyBold";
import blankProfile from "../images/blankProfile.png";
import { currentStaffYear } from "../helpers/FormatDate";

const IconButtonAnimated = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  position: "fixed",
  top: 92,
  zIndex: 4,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  marginLeft: 44,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: drawerWidth - 20,
  }),
}));

export const drawerWidth = 200;
export const drawerWidthClosed = 64;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "mobile",
})<{
  open?: boolean;
  mobile?: boolean;
}>(({ theme, open, mobile }) => ({
  marginTop: theme.spacing(mobile ? 9 : 2),
  [theme.breakpoints.up("sm")]: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: drawerWidthClosed,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    }),
  },
}));

export const Navbar = ({
  active,
  open,
  setOpen,
  title,
  mobile,
}: {
  active?: Path;
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  mobile: boolean;
}) => {
  const [collapse, setCollapse] = useState<boolean>(false);
  const history = useHistory();
  const user = useContext(UserContext);
  const roles = useContext(RolesContext);

  const theme = useTheme();

  const handleCollapse = () => setCollapse(!collapse);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };

  const DrawerListItem = ({
    icon,
    text,
    path,
    link,
  }: {
    icon: ReactNode;
    text: string;
    path?: Path;
    link?: string;
  }) => (
    <ListItemButton
      key={text}
      sx={{
        minHeight: 48,
        justifyContent: open ? "initial" : "center",
        px: 2.5,
        paddingLeft: open ? 0 : 1.5,
        paddingRight: 1.5,
        marginLeft: open ? -1.5 : 0,
      }}
      selected={!open && active === "/" + path?.split("/")[1]}
      onClick={() => (link ? window.open(link) : history.push(path ? path : ""))}
    >
      {mobile || open ? (
        <>
          <FiberManualRecordIcon
            sx={{
              opacity: active === "/" + path?.split("/")[1] ? 1 : 0,
            }}
          />
          <ListItemText
            primary={
              <TypographyBold align="center" color="textSecondary">
                {text}
              </TypographyBold>
            }
          />
        </>
      ) : (
        <Tooltip title={text} placement="right">
          <ListItemIcon
            sx={{
              justifyContent: "center",
            }}
          >
            {icon}
          </ListItemIcon>
        </Tooltip>
      )}
    </ListItemButton>
  );

  return (
    <Box sx={{ display: "flex" }}>
      {!mobile && (
        <>
          {open ? (
            <IconButtonAnimated open={open} onClick={handleDrawerClose}>
              <SvgIcon component={CollapseButton} viewBox="0 0 36 36" />
            </IconButtonAnimated>
          ) : (
            <IconButtonAnimated open={open} onClick={handleDrawerOpen}>
              <SvgIcon component={ExpandButton} viewBox="0 0 36 36" />
            </IconButtonAnimated>
          )}
        </>
      )}
      {mobile && (
        <AppBar position="fixed" sx={{ zIndex: 2 }}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{
                mr: 2,
              }}
              onClick={() => setOpen(true)}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
              }}
            >
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
      )}
      <DrawerNavbar
        mobile={mobile}
        sx={{
          zIndex: 3,
        }}
        variant="permanent"
        color="primary"
        onClose={toggleDrawer(false)}
        onOpen={handleDrawerOpen}
        open={open}
      >
        {open ? (
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Box
              component="img"
              sx={{
                flexGrow: 1,
                objectFit: "contain",
                maxHeight: theme.spacing(6.5),
                maxWidth: theme.spacing(12.5),
                cursor: "pointer",
                mt: theme.spacing(6),
              }}
              alt="SOW Logo"
              onClick={() => history.push(Path.Dashboard)}
              src={theShedAndSowLogo}
            />
          </Grid>
        ) : (
          <Grid container alignItems="center" justifyContent="center">
            <Box
              sx={{
                flexGrow: 1,
                objectFit: "contain",
                cursor: "pointer",
                maxHeight: theme.spacing(3.5),
                mt: theme.spacing(6),
              }}
              component="img"
              alt="SOW Logo"
              onClick={() => history.push(Path.Dashboard)}
              src={sowLogo}
            />
          </Grid>
        )}
        <DrawerHeader></DrawerHeader>
        {open ? (
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Box
              component="img"
              sx={{
                mb: theme.spacing(2),
                width: theme.spacing(15),
                height: theme.spacing(15),
                overflow: "hidden",
                objectFit: "cover",
                borderRadius: "50%",
              }}
              alt="Profile"
              src={user.photoURL ? user.photoURL : blankProfile}
            />
            <Typography variant="h6">
              {user.firstName} {user.lastName}
            </Typography>
            <Button onClick={() => history.push(`${Path.Profile}/${user.id}`)} variant="outlined" size="small">
              <Typography variant="body2" color="textSecondary">
                Edit Profile
              </Typography>
            </Button>
          </Grid>
        ) : (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{
              mb: theme.spacing(1),
            }}
          >
            <Box
              component="img"
              sx={{
                mb: theme.spacing(1),
                width: theme.spacing(5),
                height: theme.spacing(5),
                overflow: "hidden",
                objectFit: "cover",
                borderRadius: "50%",
              }}
              alt="Profile"
              style={{
                cursor: "pointer",
              }}
              src={user.photoURL ? user.photoURL : blankProfile}
              onClick={() => history.push(`${Path.Profile}/${user.id}`)}
            />
          </Grid>
        )}
        <List>
          {((user as Staff).department === DATA_AND_IT ||
            (user as Staff).department === TRAINING_AND_DEVELOPMENT ||
            (user as Staff).department === HUMAN_RESOURCES) && (
            <DrawerListItem text={"Modules"} icon={<LibraryBooksIcon />} path={Path.Modules} />
          )}
          <DrawerListItem
            text={"Org Chart"}
            icon={<AccountTreeIcon />}
            path={`${Path["Organisation Chart"]}/${currentStaffYear}` as Path}
          />
          <DrawerListItem
            text={"PDP"}
            icon={<PlaylistAddCheckIcon />}
            path={`${Path["Personal Development Plan"]}/${user.id}` as Path}
          />
          <DrawerListItem text={"360 Reviews"} icon={<RateReviewIcon />} path={Path["360 Reviews"]} />
          {/* <DrawerListItem text={"Contact Us"} icon={<ContactPageIcon />} path={Path["Contact Us"]} /> */}
          {/* <DrawerListItem
            text={"Missions Support"}
            icon={<MonetizationOnIcon />}
            path={`${Path["Missions Support"]}/${user.id}` as Path}
          /> */}
          {atLeast(roles, STAFF, user.role) && (
            <Box sx={{ mt: theme.spacing(1) }}>
              <ListItemButton
                sx={{
                  mt: theme.spacing(1),
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  paddingLeft: open ? 0 : 1.5,
                  paddingRight: 1.5,
                  marginLeft: open ? -1.5 : 0,
                }}
                selected={
                  active === Path["Reimbursement Requests"] ||
                  active === Path["Design Requests"] ||
                  active === Path["Promotion Requests"]
                }
                onClick={handleCollapse}
              >
                {open ? (
                  <ListItemText
                    primary={
                      <TypographyBold align="center" color="textSecondary">
                        Request Forms
                      </TypographyBold>
                    }
                  />
                ) : (
                  <ListItemIcon
                    sx={{
                      justifyContent: "center",
                    }}
                  >
                    <DescriptionIcon />
                  </ListItemIcon>
                )}
                {open ? collapse ? <ExpandLess /> : <ExpandMore /> : <></>}
              </ListItemButton>
              <Collapse in={collapse} timeout="auto" unmountOnExit>
                <List disablePadding>
                  <DrawerListItem
                    text={"Reimbursement"}
                    icon={<MonetizationOnIcon />}
                    path={Path["Reimbursement Requests"]}
                  />
                  <DrawerListItem text={"Design"} icon={<BrushIcon />} path={Path["Design Requests"]} />
                  <DrawerListItem text={"Promotion"} icon={<CampaignIcon />} path={Path["Promotion Requests"]} />
                </List>
              </Collapse>
              <Box sx={{ mt: theme.spacing(1) }}>
                <DrawerListItem text={"Metrics"} icon={<SsidChartIcon />} path={Path.Metrics} />
              </Box>
              {/* <DrawerListItem text={"Budgets"} icon={<BarChartIcon />} path={Path["Budgets"]} /> */}
              {((user as Staff).department === DATA_AND_IT ||
                (user as Staff).department === TRAINING_AND_DEVELOPMENT ||
                (user as Staff).department === HUMAN_RESOURCES ||
                user.role === DIRECTOR) && (
                <Box sx={{ mt: theme.spacing(1) }}>
                  <DrawerListItem text={"All Modules"} icon={<LibraryBooksIcon />} path={Path["All Modules"]} />
                  <DrawerListItem text={"Edit Modules"} icon={<EditIcon />} path={Path["Edit Modules"]} />
                  <DrawerListItem
                    text={"Module Answers"}
                    icon={<AssignmentIndIcon />}
                    path={
                      `${Path["Module Answers"]}/${currentStaffYear}/${new Date().toLocaleString("default", {
                        month: "long",
                      })}` as Path
                    }
                  />
                </Box>
              )}
              {((user as Staff).department === DATA_AND_IT ||
                (user as Staff).department === PEOPLE_AND_CULTURE ||
                (user as Staff).department === HUMAN_RESOURCES) && (
                <Box sx={{ mt: theme.spacing(1) }}>
                  <DrawerListItem text={"360 Reviews Setup"} icon={<SettingsIcon />} path={Path["360 Reviews Setup"]} />
                  <DrawerListItem
                    text={"Org Structure"}
                    icon={<AccountTreeIcon />}
                    path={Path["Organisation Structure"]}
                  />
                  <DrawerListItem text={"Update Users"} icon={<AssignmentIndIcon />} path={Path["Update Users"]} />
                </Box>
              )}
              <DrawerListItem
                text={"Update Mentors"}
                icon={<SupervisorAccountIcon />}
                path={Path["Update Mentees and Mentors"]}
              />
              {((user as Staff).department === DATA_AND_IT || (user as Staff).department === FINANCE) && (
                <DrawerListItem text={"Set Budget Manager"} icon={<EditIcon />} path={Path["Set Budget Manager"]} />
              )}
              {(user as Staff).department === DATA_AND_IT && (
                <DrawerListItem
                  text={"Registration Reviewer"}
                  icon={<EditIcon />}
                  path={Path["Set Registration Reviewer"]}
                />
              )}
              <DrawerListItem text={"Event Form"} icon={<EventIcon />} path={Path["Event Forms"]} />
              <DrawerListItem text={"View Feedback"} icon={<RateReviewIcon />} path={Path["View Feedback"]} />
              <DrawerListItem text={"Pledges"} icon={<SsidChartIcon />} path={Path["Staff and Alumni Pledges"]} />
            </Box>
          )}
        </List>
        {open && (
          <Grid
            container
            sx={{ m: theme.spacing(6, 0, 2, 0) }}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Button
              onClick={() => {
                logout().then(() => handleDrawerClose());
              }}
              variant="outlined"
              size="small"
            >
              <Typography variant="body2" color="textSecondary">
                Logout
              </Typography>
            </Button>
          </Grid>
        )}
      </DrawerNavbar>
    </Box>
  );
};
