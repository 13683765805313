import {
  collection,
  doc,
  DocumentData,
  getDocs,
  setDoc,
  query,
  QuerySnapshot,
  updateDoc,
  deleteDoc,
  QueryConstraint,
  where,
} from "firebase/firestore";
import { RequestStatus } from "../data/RequestStatus";
import { DIRECTOR, HEAD_OF_DEPARTMENT } from "../data/Roles";
import { SOW_WEBSITE } from "../helpers/Env";
import { currentStaffYear } from "../helpers/FormatDate";
import { Path } from "../helpers/Path";
import { DepartmentInterface } from "../models/Department";
import { FinanceRequestEmailForm } from "../models/FinanceRequestEmailForm";
import { Reimbursement } from "../models/Reimbursement";
import { Request } from "../models/Request";
import { RequestDeclined } from "../models/RequestDeclined";
import { Staff } from "../models/Staff";
import { getStaffYearDepartments } from "./departments";
import { sendFinanceRequestEmail } from "./email";
import { db } from "./firebase";
import { paid } from "./reimbursements";
import { getDirector, getFinanceHead, getHODByDepartment, getUserByIdYear } from "./users";

const AMOUNT_REQUIRED_FOR_DIRECTORS_APPROVAL = 5000;

export const requestStatus = (request: Request): RequestStatus => {
  if (requestApprovedOrCompleted(request)) {
    return RequestStatus.APPROVED;
  } else if (requestDeclined(request)) {
    return RequestStatus.DECLINED;
  } else {
    return RequestStatus.PENDING;
  }
};

export const requestApprovedOrCompleted = (request: Request): boolean =>
  approved(request.approvedByHOD) &&
  approved(request.approvedByFinance) &&
  approved(request.approvedByFinanceHead) &&
  (request.approvedByDirector ? approved(request.approvedByDirector) : true);

export const requestApproved = (request: Request) =>
  approved(request.approvedByHOD) &&
  approved(request.approvedByFinance) &&
  approved(request.approvedByFinanceHead) &&
  (request.approvedByDirector ? approved(request.approvedByDirector) : true) &&
  !paid(request as Reimbursement);

export const requestCompleted = (request: Request) => requestDeclined(request) || paid(request as Reimbursement);

export const requestDeclined = (request: Request): boolean =>
  declined(request.approvedByHOD) ||
  declined(request.approvedByFinance) ||
  declined(request.approvedByFinanceHead) ||
  (request.approvedByDirector ? declined(request.approvedByDirector) : false);

export const requestPending = (request: Request): boolean =>
  !requestDeclined(request) &&
  (pending(request.approvedByHOD) ||
    pending(request.approvedByFinance) ||
    pending(request.approvedByFinanceHead) ||
    (request.approvedByDirector ? pending(request.approvedByDirector) : false));

export const getAllRequests = (staff: Staff, departments: DepartmentInterface): Promise<Promise<Request[]>[]> =>
  new Promise((resolve) =>
    resolve([
      getMyRequests(staff),
      getHODRequests(staff, departments),
      getBudgetManagerRequests(staff, departments),
      getFinanceHeadRequests(staff, departments),
      getDirectorRequests(staff),
    ])
  );

const getDataFromFirestoreYear = (res: QuerySnapshot<DocumentData>, year: string) =>
  res.docs.map(async (doc) => {
    const departments = await getStaffYearDepartments(year);
    let hodFetch = undefined;
    let staffFetch = undefined;
    try {
      hodFetch = await getHODByDepartment(departments, doc.data().department);
      staffFetch = await getUserByIdYear(doc.data().userID, year);
    } catch (e) {}
    const financeFetch = await getUserByIdYear(departments.BUDGET_MANAGER, year);
    const financeHeadFetch = await getFinanceHead(departments);
    const directorFetch =
      Number(doc.data().amount) >= AMOUNT_REQUIRED_FOR_DIRECTORS_APPROVAL
        ? await getDirector(year)
        : await (new Promise((resolve) => resolve(undefined)) as Promise<Staff | undefined>);
    return {
      ...doc.data(),
      staff: staffFetch,
      hod: hodFetch,
      finance: financeFetch,
      financeHead: financeHeadFetch,
      director: directorFetch,
      submittedTime: doc.data().submittedTime.toDate(),
      approvedTime: doc.data().approvedTime ? doc.data().approvedTime.toDate() : undefined,
      declinedTime: doc.data().declinedTime ? doc.data().declinedTime.toDate() : undefined,
      paidTime: doc.data().paidTime ? doc.data().paidTime.toDate() : undefined,
      id: doc.id,
    } as Request;
  });

const getDataFromFirestore = (res: QuerySnapshot<DocumentData>) =>
  getDataFromFirestoreYear(res, currentStaffYear.toString());

export const getYearRequests = (year: string): Promise<Request[]> =>
  new Promise(async (resolve, reject) => {
    getDocs(collection(db, "requests", "requests", year))
      .then((res) => {
        const p = getDataFromFirestoreYear(res, year);
        Promise.all(p).then((list) => resolve(list.sort((a, b) => (a.submittedTime > b.submittedTime ? 1 : -1))));
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getMyRequests = (staff: Staff): Promise<Request[]> => getRequestsWhere([where("userID", "==", staff.id)]);

export const getHODRequests = async (staff: Staff, departments: DepartmentInterface): Promise<Request[]> => {
  const getHODDepartments = Object.entries(departments.departments)
    .filter(([dep, content]) => content.head.id === staff.id && dep !== departments.FINANCE)
    .map(([dep, _]) => dep);
  return await getRequestsWhere([
    where("department", "in", getHODDepartments.length > 0 ? getHODDepartments : ["N/A"]),
    where("userID", "!=", staff.id),
  ]);
};

export const getBudgetManagerRequests = (staff: Staff, departments: DepartmentInterface): Promise<Request[]> => {
  if (staff.id === departments.BUDGET_MANAGER) {
    return getRequestsWhere([where("userID", "!=", staff.id)]);
  } else {
    return new Promise((resolve) => {
      resolve([]);
    });
  }
};

export const getFinanceHeadRequests = (staff: Staff, departments: DepartmentInterface): Promise<Request[]> => {
  if (departments.departments[departments.FINANCE].head.id === staff.id) {
    return getRequestsWhere([where("userID", "!=", staff.id)]);
  } else {
    return new Promise((resolve) => {
      resolve([]);
    });
  }
};

export const getDirectorRequests = (staff: Staff): Promise<Request[]> => {
  if (staff.role === DIRECTOR) {
    return getRequestsWhere([
      where("approvedByDirector", "in", [RequestStatus.PENDING, RequestStatus.APPROVED, RequestStatus.DECLINED]),
      where("userID", "!=", staff.id),
    ]);
  } else {
    return new Promise((resolve) => {
      resolve([]);
    });
  }
};

export type WhereFilterOp =
  | "<"
  | "<="
  | "=="
  | "!="
  | ">="
  | ">"
  | "array-contains"
  | "in"
  | "array-contains-any"
  | "not-in";

const getRequestsWhere = (queries: QueryConstraint[]): Promise<Request[]> =>
  new Promise((resolve, reject) =>
    getDocs(query(collection(db, "requests", "requests", currentStaffYear.toString()), ...queries))
      .then((res) => {
        const p = getDataFromFirestore(res);
        Promise.all(p).then((list) => resolve(list.sort((a, b) => (a.submittedTime > b.submittedTime ? 1 : -1))));
      })
      .catch((error) => {
        reject(error);
      })
  );

const sendApproveEmail = (request: Request) =>
  sendFinanceRequestEmail({
    email: request.staff.email,
    subject: "Your request: #" + request.id + " of $" + request.amount + " has been approved",
    name: request.staff.firstName,
    message: "You request has been approved. Please log in to THE SHED and submit the receipt/invoice.",
    request: request,
    website: SOW_WEBSITE + Path["Reimbursement Requests"],
  } as FinanceRequestEmailForm);

const sendApproveEmailToApprovers = (request: Request, departments: DepartmentInterface) => {
  if (request.department !== departments.FINANCE) {
    sendFinanceRequestEmail({
      email: request.hod?.email,
      subject:
        "The request: #" +
        request.id +
        " of $" +
        request.amount +
        " by " +
        request.staff.firstName +
        " has been approved",
      name: request.hod?.firstName,
      message: "The request has been approved.",
      request: request,
      website: SOW_WEBSITE + Path["Reimbursement Requests"],
    } as FinanceRequestEmailForm);
  }
  sendFinanceRequestEmail({
    email: request.finance?.email,
    subject:
      "The request: #" +
      request.id +
      " of $" +
      request.amount +
      " by " +
      request.staff.firstName +
      " has been approved",
    name: request.finance?.firstName,
    message: "The request has been approved.",
    request: request,
    website: SOW_WEBSITE + Path["Reimbursement Requests"],
  } as FinanceRequestEmailForm);
  sendFinanceRequestEmail({
    email: request.financeHead?.email,
    subject:
      "The request: #" +
      request.id +
      " of $" +
      request.amount +
      " by " +
      request.staff.firstName +
      " has been approved",
    name: request.financeHead?.firstName,
    message: "The request has been approved.",
    request: request,
    website: SOW_WEBSITE + Path["Reimbursement Requests"],
  } as FinanceRequestEmailForm);
  if (request.director) {
    sendFinanceRequestEmail({
      email: request.director?.email,
      subject:
        "The request: #" +
        request.id +
        " of $" +
        request.amount +
        " by " +
        request.staff.firstName +
        " has been approved",
      name: request.director?.firstName,
      message: "The request has been approved.",
      request: request,
      website: SOW_WEBSITE + Path["Reimbursement Requests"],
    } as FinanceRequestEmailForm);
  }
};

const staffWhoDeclined = (request: RequestDeclined) => {
  if (declined(request.approvedByDirector)) {
    return "Director (" + request.director?.firstName + ")";
  } else if (declined(request.approvedByFinance)) {
    return "Finance (" + request.finance?.firstName + ")";
  } else if (declined(request.approvedByFinanceHead)) {
    return "Finance Head (" + request.financeHead?.firstName + ")";
  } else if (declined(request.approvedByHOD)) {
    return "HOD (" + request.hod?.firstName + ")";
  }
};

const sendDeclineEmailTo = (request: RequestDeclined, staff: Staff) =>
  sendFinanceRequestEmail({
    email: staff.email,
    subject:
      "The request: #" +
      request.id +
      " of $" +
      request.amount +
      " by " +
      request.staff.firstName +
      " has been declined",
    name: staff.firstName,
    message: "The request has been declined by " + staffWhoDeclined(request) + ". Reason: " + request.reason + ".",
    request: request,
    website: SOW_WEBSITE + Path["Reimbursement Requests"],
  } as FinanceRequestEmailForm);

const sendDeclineEmail = (request: RequestDeclined) =>
  sendFinanceRequestEmail({
    email: request.staff.email,
    subject: "Your request: #" + request.id + " of $" + request.amount + " has been declined",
    name: request.staff.firstName,
    message: "Your request has been declined by " + staffWhoDeclined(request) + " Reason: " + request.reason + ".",
    request: request,
    website: SOW_WEBSITE + Path["Reimbursement Requests"],
  } as FinanceRequestEmailForm);

export const decline = (request: RequestDeclined, declinedBy: string): Promise<RequestDeclined> => {
  let requestRejected: RequestDeclined = {
    ...request,
    [declinedBy]: RequestStatus.DECLINED,
    declinedTime: new Date(),
  };
  return new Promise((resolve, reject) => {
    updateDoc(doc(db, "requests", "requests", currentStaffYear.toString(), requestRejected.id as string), {
      [declinedBy]: RequestStatus.DECLINED,
      declinedTime: requestRejected.declinedTime,
      reason: requestRejected.reason,
    })
      .then(() => {
        sendDeclineEmail(requestRejected);
        if (approved(requestRejected.approvedByHOD) && requestRejected.hod) {
          sendDeclineEmailTo(requestRejected, requestRejected.hod);
        } else if (approved(requestRejected.approvedByFinance) && requestRejected.finance) {
          sendDeclineEmailTo(requestRejected, requestRejected.finance);
        } else if (approved(requestRejected.approvedByFinanceHead) && requestRejected.financeHead) {
          sendDeclineEmailTo(requestRejected, requestRejected.financeHead);
        } else if (approved(requestRejected.approvedByDirector) && requestRejected.director) {
          sendDeclineEmailTo(requestRejected, requestRejected.director);
        }
        resolve(requestRejected);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const approve = (request: Request, approvedBy: string, departments: DepartmentInterface): Promise<Request> => {
  request = {
    ...request,
    [approvedBy]: RequestStatus.APPROVED,
  };
  return new Promise((resolve, reject) => {
    if (requestApprovedOrCompleted(request)) {
      let approvedTime = new Date();
      request = {
        ...request,
        approvedTime: approvedTime,
      };
      updateDoc(doc(db, "requests", "requests", currentStaffYear.toString(), request.id as string), {
        [approvedBy]: RequestStatus.APPROVED,
        approvedTime: approvedTime,
      })
        .then(() => {
          sendApproveEmail(request);
          sendApproveEmailToApprovers(request, departments);
          resolve(request);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      updateDoc(doc(db, "requests", "requests", currentStaffYear.toString(), request.id as string), {
        [approvedBy]: RequestStatus.APPROVED,
      })
        .then(() => {
          sendSubmitRequestEmail(request);
          resolve(request);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
};

export const submitRequest = (request: Request, departments: DepartmentInterface): Promise<Request> =>
  new Promise((resolve, reject) => {
    getDocs(collection(db, "requests", "requests", currentStaffYear.toString()))
      .then((res) => {
        const id_new = (res.docs.length > 0 ? Math.max(...res.docs.map((d) => Number(d.id))) + 1 : 1).toString();
        const hodFetch = getHODByDepartment(departments, request.department);
        const financeFetch = getUserByIdYear(departments.BUDGET_MANAGER, currentStaffYear.toString());
        const financeHeadFetch = getFinanceHead(departments);
        const directorFetch =
          Number(request.amount) > AMOUNT_REQUIRED_FOR_DIRECTORS_APPROVAL
            ? getDirector(currentStaffYear.toString())
            : (new Promise((resolve) => resolve(undefined)) as Promise<Staff | undefined>);
        Promise.all([hodFetch, financeFetch, financeHeadFetch, directorFetch]).then(
          ([hod_in, finance_in, financeHead_in, director_in]) => {
            request = {
              ...request,
              hod: hod_in,
              finance: finance_in as Staff,
              financeHead: financeHead_in,
              director: director_in,
              id: id_new,
            };
            approveRedundant(request, departments);
            const { staff, hod, finance, financeHead, director, id, ...requestWithoutStaff } = request;
            setDoc(doc(db, "requests", "requests", currentStaffYear.toString(), id_new), {
              ...requestWithoutStaff,
              userID: request.staff.id,
            })
              .then(() => {
                sendSubmitRequestEmail(request);
                sendEmailSent(request);
                resolve(request);
              })
              .catch((error) => {
                reject(error);
              });
          }
        );
      })
      .catch((error) => {
        reject(error);
      });
  });

const sendCancelledTo = (request: Request, staff: Staff) =>
  sendFinanceRequestEmail({
    email: staff.email,
    subject:
      "The request: #" +
      request.id +
      " of $" +
      request.amount +
      " by " +
      request.staff.firstName +
      " has been cancelled",
    name: staff.firstName,
    message: "The request has been cancelled. It will no longer show up on the dashboard.",
    request: request,
    website: SOW_WEBSITE + Path["Reimbursement Requests"],
  } as FinanceRequestEmailForm);

const sendCancelledToRelevantPeople = (r: Request) => {
  if (approved(r.approvedByHOD)) {
    sendCancelledTo(r, r.hod as Staff);
  }
  if (approved(r.approvedByFinance)) {
    sendCancelledTo(r, r.finance as Staff);
  }
  if (approved(r.approvedByDirector)) {
    sendCancelledTo(r, r.director as Staff);
  }
  if (approved(r.approvedByFinanceHead)) {
    sendCancelledTo(r, r.financeHead as Staff);
  }
  if (pending(r.approvedByHOD)) {
    sendCancelledTo(r, r.hod as Staff);
  }
  if (approved(r.approvedByHOD) && pending(r.approvedByFinance)) {
    sendCancelledTo(r, r.finance as Staff);
  }
  if (r.approvedByDirector !== undefined) {
    if (approved(r.approvedByHOD) && approved(r.approvedByFinance) && pending(r.approvedByDirector)) {
      sendCancelledTo(r, r.director as Staff);
    }
    if (
      approved(r.approvedByHOD) &&
      approved(r.approvedByFinance) &&
      approved(r.approvedByDirector) &&
      pending(r.approvedByFinanceHead)
    ) {
      sendCancelledTo(r, r.financeHead as Staff);
    }
  } else {
    if (approved(r.approvedByHOD) && approved(r.approvedByFinance) && pending(r.approvedByFinanceHead)) {
      sendCancelledTo(r, r.financeHead as Staff);
    }
    if (approved(r.approvedByHOD) && approved(r.approvedByFinance) && pending(r.approvedByFinanceHead)) {
      sendCancelledTo(r, r.financeHead as Staff);
    }
  }
};

export const cancel = (request: Request): Promise<Request> =>
  new Promise((resolve, reject) => {
    deleteDoc(doc(db, "requests", "requests", currentStaffYear.toString(), request.id as string))
      .then(() => {
        sendCancelledToRelevantPeople(request);
        resolve(request);
      })
      .catch((error) => reject(error));
  });

export const pending = (requestStatus: RequestStatus | undefined) => requestStatus === RequestStatus.PENDING;

export const approved = (requestStatus: RequestStatus | undefined) => requestStatus === RequestStatus.APPROVED;

export const declined = (requestStatus: RequestStatus | undefined) => requestStatus === RequestStatus.DECLINED;

const approveRedundant = (request: Request, departments: DepartmentInterface) => {
  if (request.director) {
    request.approvedByDirector = RequestStatus.PENDING;
  }
  if (
    request.department === request.staff.department &&
    (request.staff.role === HEAD_OF_DEPARTMENT || request.staff.role === DIRECTOR)
  ) {
    request.approvedByHOD = RequestStatus.APPROVED;
  }
  if (request.staff.department === departments.FINANCE) {
    if (request.finance?.id === request.staff.id) {
      request.approvedByFinance = RequestStatus.APPROVED;
    }
  }
  if (request.department === departments.FINANCE) {
    request.approvedByHOD = RequestStatus.APPROVED;
    if (request.staff.role === HEAD_OF_DEPARTMENT) {
      request.approvedByFinanceHead = RequestStatus.APPROVED;
    }
  }
};

const sendSubmitRequestEmail = (request: Request) => {
  if (pending(request.approvedByHOD)) {
    sendNeedApprovalEmail(request.hod as Staff, request);
  } else if (pending(request.approvedByFinance)) {
    sendNeedApprovalEmail(request.finance as Staff, request);
  } else if (pending(request.approvedByDirector)) {
    sendNeedApprovalEmail(request.director as Staff, request);
  } else if (pending(request.approvedByFinanceHead)) {
    sendNeedApprovalEmail(request.financeHead as Staff, request);
  }
};

const sendNeedApprovalEmail = (staff: Staff, request: Request) =>
  sendFinanceRequestEmail({
    email: staff.email,
    subject: "The request: #" + request.id + " of $" + request.amount + " needs your approval",
    name: staff.firstName,
    message: "The request below requires your approval. To approve, please go to the dashboard through the link below.",
    request: request,
    website: SOW_WEBSITE + Path["Reimbursement Requests"],
  } as FinanceRequestEmailForm);

const sendEmailSent = (request: Request) =>
  sendFinanceRequestEmail({
    email: request.staff.email,
    subject: "Your request: #" + request.id + " of $" + request.amount + " has been submitted",
    name: request.staff.firstName,
    message:
      "The request below has been submitted to the " +
      request.department +
      " Department Head and Finance Team. Once approved, an email will be sent so you can proceed with attaching receipt/invoice.",
    request: request,
    website: SOW_WEBSITE + Path["Reimbursement Requests"],
  } as FinanceRequestEmailForm);

const splitMyRequests = (requests: Request[]) => ({
  pending: requests.filter((r) => requestPending(r)),
  approved: requests.filter((r) => requestApproved(r)),
  completed: requests.filter((r) => requestCompleted(r)),
});

const splitPendingRequests = (requests: Request[], approvedBy: keyof Request) => {
  return requests.filter((r) => {
    if (!requestPending(r)) {
      return false;
    }
    if (approvedBy === "approvedByHOD") {
      return pending(r["approvedByHOD"]);
    } else if (approvedBy === "approvedByFinance") {
      return approved(r["approvedByHOD"]) && pending(r["approvedByFinance"]);
    } else if (approvedBy === "approvedByDirector") {
      return approved(r["approvedByHOD"]) && approved(r["approvedByFinance"]) && pending(r["approvedByDirector"]);
    } else if (approvedBy === "approvedByFinanceHead") {
      return (
        approved(r["approvedByHOD"]) &&
        approved(r["approvedByFinance"]) &&
        (r["approvedByDirector"] !== undefined ? approved(r["approvedByDirector"]) : true) &&
        pending(r["approvedByFinanceHead"])
      );
    }
    return false;
  });
};

const splitRequests = (requests: Request[], approvedBy: keyof Request) => ({
  pending: splitPendingRequests(requests, approvedBy),
  approved: requests.filter((r) => r[approvedBy] === RequestStatus.APPROVED && !requestCompleted(r)),
  completed: requests.filter((r) => requestCompleted(r)),
});

export const convertToRequestsModel = (
  myRequests: Request[],
  hodRequests: Request[],
  budgetManagerRequests: Request[],
  financeHeadRequests: Request[],
  directorRequests: Request[]
) => ({
  myRequests: splitMyRequests(myRequests),
  hodRequests: splitRequests(hodRequests, "approvedByHOD"),
  budgetManagerRequests: splitRequests(budgetManagerRequests, "approvedByFinance"),
  financeHeadRequests: splitRequests(financeHeadRequests, "approvedByFinanceHead"),
  directorRequests: splitRequests(directorRequests, "approvedByDirector"),
});
