import { useContext, useEffect, useState } from "react";
import { Loading } from "../../components/Loading";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ModuleModel } from "../../models/ModuleModel";
import { ModuleSAQ } from "../../models/ModuleSAQ";
import {
  completeModule,
  getModule,
  getStats,
  incrementCurrentStep,
  updateQuestionAnswers,
  updateSAQAnswers,
} from "../../services/modules";
import { useHistory, useParams } from "react-router-dom";
import { Box, Button, Container, Grid, LinearProgress, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { SAQ } from "./SAQ";
import { NavBarIconButton } from "../../components/NavBarIconButton";
import { UserContext } from "../../components/PrivateRoute";
import { drawerWidth, drawerWidthClosed } from "../../components/Navbar";
import { InitModuleStats } from "../../models/ModuleStats";
import { MessageSnackbar } from "../../components/MessageSnackbar";
import { InitMessageState, MessageState } from "../../models/MessageState";
import { User } from "../../models/User";
import { getUserById } from "../../services/users";
import { Staff } from "../../models/Staff";
import { DATA_AND_IT, TRAINING_AND_DEVELOPMENT } from "../../data/Departments";
import { DIRECTOR } from "../../data/Roles";
import { MCQ } from "./Question";

export interface ModuleInterface {
  module?: ModuleModel;
  loading: boolean;
  viewerMode: boolean;
  maxStep: number;
  alert: MessageState;
  user?: User;
}

export const Module = ({ navbarOpen, mobile }: { navbarOpen: boolean; mobile: boolean }) => {
  const viewer = useContext(UserContext);
  const { id, userid } = useParams<{ id: string; userid: string }>();
  const theme = useTheme();
  const history = useHistory();
  const [state, setState] = useState<ModuleInterface>({
    loading: true,
    maxStep: 0,
    alert: InitMessageState,
    viewerMode: false,
  });

  const viewerIsMe = viewer.id === userid;

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });

    if (
      !viewerIsMe &&
      (viewer as Staff).department !== TRAINING_AND_DEVELOPMENT &&
      (viewer as Staff).department !== DATA_AND_IT && 
      viewer.role !== DIRECTOR
    ) {
      history.goBack();
    }

    const moduleFetch = getModule(id);
    const userFetch = getUserById(userid);

    Promise.all([moduleFetch, userFetch])
      .then(([module, user]) => {
        if (module) {
          if (
            viewerIsMe &&
            !module.availableRoles.includes(user.role) &&
            ((viewer as Staff).department === TRAINING_AND_DEVELOPMENT || (viewer as Staff).department === DATA_AND_IT || viewer.role === DIRECTOR)
          ) {
            setState({
              ...state,
              module: {
                ...module,
                moduleStats: InitModuleStats,
              },
              user,
              viewerMode: true,
              loading: false,
            });
          } else {
            getStats(user, module.id as string).then((stats) => {
              if (
                module.availableRoles.includes(user.role) ||
                (((viewer as Staff).department === TRAINING_AND_DEVELOPMENT ||
                  (viewer as Staff).department === DATA_AND_IT) &&
                  module.moduleStats.complete)
              ) {
                setState({
                  ...state,
                  module: {
                    ...module,
                    moduleStats: stats
                      ? {
                          ...stats,
                          currentStep: stats?.currentStep === module.totalSteps ? 0 : stats?.currentStep,
                        }
                      : InitModuleStats,
                  },
                  user,
                  loading: false,
                  maxStep: stats ? stats.currentStep : 0,
                });
              } else {
                history.goBack();
              }
            });
          }
        } else {
          history.goBack();
        }
      })
      .catch((err) => history.goBack());
    // eslint-disable-next-line
  }, [id, history]);

  const { module, loading, maxStep, alert, user, viewerMode } = state;

  if (module) {
    const handleNext = () => {
      user && !viewerMode
        ? incrementCurrentStep(user, module, maxStep).then((module) => {
            setState({
              ...state,
              module,
              maxStep: maxStep < module.moduleStats.currentStep ? module.moduleStats.currentStep : maxStep,
            });
          })
        : setState({
            ...state,
            module: {
              ...module,
              moduleStats: {
                ...module.moduleStats,
                currentStep: module.moduleStats.currentStep + 1,
              },
            },
            maxStep: maxStep < module.moduleStats.currentStep + 1 ? module.moduleStats.currentStep + 1 : maxStep,
          });
    };

    const handleBack = () => {
      if (user && module) {
        setState({
          ...state,
          module: {
            ...module,
            moduleStats: {
              ...module.moduleStats,
              currentStep: module.moduleStats.currentStep - 1,
            },
          },
        });
      }
    };

    const updateSAQ = (q: { [x: string]: string }, currentStep: number) =>
      user && !viewerMode
        ? updateSAQAnswers(user, module, q, currentStep).then((module) => {
            setState({
              ...state,
              module,
              alert: {
                showMessage: true,
                message: "Successfully submitted SAQ answers",
                severity: "success",
              },
            });
          })
        : setState({
            ...state,
            module,
            alert: {
              showMessage: true,
              message: "SAQ answers not submitted - viewer mode",
              severity: "info",
            },
          });

    const updateAnswers = (answers: { [x: string]: string | string[] }) =>
      user && !viewerMode
        ? updateQuestionAnswers(user, module, answers).then(() => {
            module.moduleStats.answersForQuestions = answers;
            setState({
              ...state,
              module,
              alert: {
                showMessage: true,
                message: "Successfully saved answers",
                severity: "success",
              },
            });
          })
        : setState({
            ...state,
            alert: {
              showMessage: true,
              message: "Answers not updated - viewer mode",
              severity: "info",
            },
          });

    const finishModule = () =>
      user && !viewerMode
        ? incrementCurrentStep(user, module, maxStep).then((module) => {
            completeModule(user, module).then(() => {
              history.goBack();
            });
          })
        : history.goBack();

    const canProceedToNextStep = () => {
      if (viewerMode) return true;
      let currentStepSAQ = module.contents[module.moduleStats.currentStep].saq;
      if (currentStepSAQ && currentStepSAQ.length > 0) {
        if (
          Object.keys(module.moduleStats.answers).length === 0 ||
          module.moduleStats.answers[module.moduleStats.currentStep] === undefined
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    };

    const canFinish = () => {
      if (viewerMode) return true;
      let isLastStep = module.moduleStats.currentStep === module.contents.length - 1;
      if (isLastStep) {
        // check for current saq
        if (module.questions.length > 0) {
          // check for mcq
          if (Object.keys(module.moduleStats.answersForQuestions).length === 0) {
            return false;
          }
        }
      }
      return canProceedToNextStep();
    };

    if (loading) {
      return <Loading />;
    }
    return (
      <>
        <MessageSnackbar messageState={alert} setMessageState={(alert) => setState({ ...state, alert })} />
        <Typography
          sx={{
            marginLeft: theme.spacing(4),
          }}
        >
          {module?.title}
        </Typography>
        <NavBarIconButton
          onClick={() => history.goBack()}
          sx={{
            marginLeft: theme.spacing(2),
          }}
        >
          <ArrowBackIcon color="primary" />
        </NavBarIconButton>
        <Container
          maxWidth="md"
          sx={{
            mb: theme.spacing(17.5),
          }}
        >
          <Typography variant="h3">{module.contents[module.moduleStats.currentStep].title}</Typography>
          {module.contents[module.moduleStats.currentStep].content.map((c, i) => (
            <Typography
              sx={{
                mb: theme.spacing(1),
                textDecoration: c.underline ? "underline" : null,
                fontStyle: c.italic ? "italic" : null,
                fontWeight: c.bold ? "bold" : null,
              }}
              key={i}
              variant={c.variant}
            >
              {c.text}
            </Typography>
          ))}
          {module.contents[module.moduleStats.currentStep].youtubeLink && (
            <Grid container justifyContent="center">
              <iframe
                width="560"
                height="315"
                src={module.contents[module.moduleStats.currentStep].youtubeLink}
                title="YouTube video player"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </Grid>
          )}
          {module.contents[module.moduleStats.currentStep].imageURL && (
            <Grid xs={12} container item justifyContent="center">
              <Box
                component="img"
                alt={"Content image #" + module.moduleStats.currentStep + 1}
                src={module.contents[module.moduleStats.currentStep].imageURL}
                sx={{
                  mb: theme.spacing(2),
                  objectFit: "cover",
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              />
            </Grid>
          )}
          {module.contents[module.moduleStats.currentStep].saq && (
            <SAQ
              i={module.moduleStats.currentStep}
              updateSAQ={(q) => updateSAQ(q, module.moduleStats.currentStep)}
              saq={module.contents[module.moduleStats.currentStep].saq as ModuleSAQ[]}
              answers={module.moduleStats.answers ? module.moduleStats.answers[module.moduleStats.currentStep] : {}}
              completed={module.moduleStats.answers[module.moduleStats.currentStep] !== undefined}
            />
          )}
          {module.questions && module.questions.length !== 0 && maxStep >= module.totalSteps - 1 && (
            <MCQ
              questions={module.questions}
              answers={module.moduleStats.answersForQuestions ? module.moduleStats.answersForQuestions : {}}
              updateAnswers={(answers) => updateAnswers(answers)}
              completed={Object.keys(module.moduleStats.answersForQuestions).length === module.questions.length}
            />
          )}
        </Container>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            right: 0,
            width: mobile ? "100%" : `calc(100% - ${navbarOpen ? drawerWidth : drawerWidthClosed}px)`,
            backdropFilter: "blur(5px)",
            backgroundColor: "rgb(242, 243, 245, 0.2)",
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-evenly"
            item
            sx={{
              mb: theme.spacing(1),
            }}
          >
            <Grid item>
              <Button disabled={module.moduleStats.currentStep === 0} onClick={handleBack}>
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={!canFinish()}
                variant="contained"
                color="primary"
                onClick={module.moduleStats.currentStep === module.contents.length - 1 ? finishModule : handleNext}
              >
                {module.moduleStats.currentStep === module.contents.length - 1 ? "Finish" : "Next"}
              </Button>
            </Grid>
          </Grid>
          <LinearProgress
            variant="determinate"
            value={module.moduleStats ? (module.moduleStats.currentStep / module.totalSteps) * 100 : 0}
          />
        </Box>
      </>
    );
  } else {
    return <Loading />;
  }
};
