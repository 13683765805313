import {
  DocumentData,
  DocumentSnapshot,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { currentStaffYear } from "../helpers/FormatDate";
import {
  EventFormModel,
  EventFormSubmit,
  FinanceSubFormModel,
  InitFinanceSubFormModel,
  InitMarketingSubFormModel,
  InitRegistrationSubFormModel,
  RegistrationSubFormModel,
  SubFormModel,
  SubFormStatus,
  SubFormType,
} from "../models/EventFormModel";
import { sendEventFormEmail } from "./email";
import { db } from "./firebase";
import { getUserById } from "./users";

const EVENT_FORM = "eventForm";
const MARKETING_EMAIL = "design@sowaustralia.com";
const FINANCE_EMAIL = "finance@sowaustralia.com";
// const RISK_EMAIL = "risk@sowaustralia.com";
const REGISTRATION_EMAIL = "it@sowaustralia.com";

export const statusColours = {
  [SubFormStatus.SUBMITTED]: "orange",
  [SubFormStatus.APPROVED]: "green",
  [SubFormStatus.REQUEST_FOR_CHANGE]: "red",
};

export const eventFormApproved = (eventForm: EventFormModel) =>
  eventForm.marketingStatus === SubFormStatus.APPROVED &&
  eventForm.financeStatus === SubFormStatus.APPROVED &&
  // eventForm.riskStatus === SubFormStatus.APPROVED &&
  eventForm.registrationStatus === SubFormStatus.APPROVED;

export const getEventForms = async (): Promise<EventFormModel[]> => {
  const eventForms = await getDocs(collection(db, EVENT_FORM, EVENT_FORM, currentStaffYear.toString()));
  if (eventForms) {
    let eventFormList: EventFormModel[] = [];

    for (const eventForm of eventForms.docs) {
      const convertedEventForm = await convertFirestoreToEventForm(eventForm, currentStaffYear.toString());
      if (convertedEventForm) eventFormList.push(convertedEventForm);
    }
    return eventFormList;
  }
  return [];
};

export const getEventFormsYear = async (year: string): Promise<EventFormModel[]> => {
  const eventForms = await getDocs(collection(db, EVENT_FORM, EVENT_FORM, year));
  if (eventForms) {
    let eventFormList: EventFormModel[] = [];

    for (const eventForm of eventForms.docs) {
      const convertedEventForm = await convertFirestoreToEventForm(eventForm, year);
      if (convertedEventForm) eventFormList.push(convertedEventForm);
    }
    return eventFormList;
  }
  return [];
};

export const getEventForm = async (id: string, year: string): Promise<EventFormModel | undefined> => {
  const eventForm = await getDoc(doc(db, EVENT_FORM, EVENT_FORM, year, id));
  return convertFirestoreToEventForm(eventForm, year);
};

const convertFirestoreToEventForm = async (
  eventForm: DocumentSnapshot<DocumentData>,
  year: string
): Promise<EventFormModel | undefined> => {
  if (eventForm.exists())
    return {
      ...eventForm.data(),
      id: eventForm.id,
      submittee: await getUserById(eventForm.data().submittee),
      submittedTime: eventForm.data().submittedTime.toDate(),
      updatedTime: eventForm.data().updatedTime.toDate(),
      eventStartTime: eventForm.data().eventStartTime.toDate(),
      eventEndTime: eventForm.data().eventEndTime.toDate(),
      registrationOpenTime: eventForm.data().registrationOpenTime.toDate(),
      registrationCloseTime: eventForm.data().registrationCloseTime.toDate(),
      marketingStatus: (
        await getDoc(doc(db, EVENT_FORM, EVENT_FORM, year, eventForm.id, "subForms", SubFormType.MARKETING))
      ).data()?.status,
      financeStatus: (
        await getDoc(doc(db, EVENT_FORM, EVENT_FORM, year, eventForm.id, "subForms", SubFormType.FINANCE))
      ).data()?.status,
      // riskStatus: (
      //   await getDoc(doc(db, EVENT_FORM, EVENT_FORM, year, eventForm.id, "subForms", SubFormType.RISK))
      // ).data()?.status,
      registrationStatus: (
        await getDoc(doc(db, EVENT_FORM, EVENT_FORM, year, eventForm.id, "subForms", SubFormType.REGISTRATION))
      ).data()?.status,
    } as EventFormModel;
};

export const submitEventForm = async (submittedForm: EventFormSubmit): Promise<EventFormModel> => {
  const now = new Date();

  const addedDocument = await addDoc(collection(db, EVENT_FORM, EVENT_FORM, currentStaffYear.toString()), {
    ...submittedForm,
    submittedTime: now,
    updatedTime: now,
    submittee: submittedForm.submittee.id,
  });
  await setDoc(
    doc(db, EVENT_FORM, EVENT_FORM, currentStaffYear.toString(), addedDocument.id, "subForms", SubFormType.MARKETING),
    InitMarketingSubFormModel
  );
  await setDoc(
    doc(db, EVENT_FORM, EVENT_FORM, currentStaffYear.toString(), addedDocument.id, "subForms", SubFormType.FINANCE),
    InitFinanceSubFormModel
  );
  // await setDoc(
  //   doc(db, EVENT_FORM, EVENT_FORM, currentStaffYear.toString(), addedDocument.id, "subForms", SubFormType.RISK),
  //   InitRiskSubFormModel
  // );
  await setDoc(
    doc(
      db,
      EVENT_FORM,
      EVENT_FORM,
      currentStaffYear.toString(),
      addedDocument.id,
      "subForms",
      SubFormType.REGISTRATION
    ),
    InitRegistrationSubFormModel
  );

  const form: EventFormModel = { ...submittedForm, id: addedDocument.id, submittedTime: now, updatedTime: now };

  sendEventFormEmail(
    form.submittee.email,
    "Your Event Form has been submitted",
    "Please submit the Marketing, Finance, Data and IT and Risk sub forms to progress.",
    form
  );

  return form;
};

export const updateEventForm = async (
  prevForm: EventFormModel,
  submittedForm: EventFormSubmit
): Promise<EventFormModel> => {
  const now = new Date();
  const form: EventFormModel = {
    ...submittedForm,
    id: prevForm.id,
    updatedTime: now,
    submittedTime: prevForm.submittedTime,
  };

  const { id, ...formWithoutId } = form;

  await updateDoc(doc(db, EVENT_FORM, EVENT_FORM, currentStaffYear.toString(), prevForm.id), {
    ...formWithoutId,
    submittee: submittedForm.submittee.id,
  });

  sendEventFormEmail(
    form.submittee.email,
    "Your Event Form has been updated",
    "Please continue with the Marketing, Finance, Registration and Risk sub forms to progress.",
    form
  );

  // if (form.riskStatus !== SubFormStatus.APPROVED)
  //   sendEventFormEmail(
  //     RISK_EMAIL,
  //     `${form.name}'s risk form has been submitted.`,
  //     "Please review the form and approve/request for change",
  //     form
  //   );
  if (form.marketingStatus !== SubFormStatus.APPROVED)
    sendEventFormEmail(
      MARKETING_EMAIL,
      `${form.name}'s marketing form has been submitted.`,
      "Please review the form and approve/request for change",
      form
    );
  if (form.financeStatus !== SubFormStatus.APPROVED)
    sendEventFormEmail(
      FINANCE_EMAIL,
      `${form.name}'s finance form has been submitted.`,
      "Please review the form and approve/request for change",
      form
    );
  if (form.registrationStatus !== SubFormStatus.APPROVED)
    sendEventFormEmail(
      REGISTRATION_EMAIL,
      `${form.name}'s registration form has been submitted.`,
      "Please review the form and approve/request for change",
      form
    );

  return form;
};

export const getSubFormYear = async (id: string, type: SubFormType, year: string): Promise<SubFormModel> => {
  const data = (await getDoc(doc(db, EVENT_FORM, EVENT_FORM, year, id, "subForms", type))).data();
  // add all possible dates for subforms here as they need to be converted from Firestore Timestamp to JS Date
  return {
    ...data,
    approvedByBudgetManagerTime: data?.approvedByBudgetManagerTime
      ? data.approvedByBudgetManagerTime.toDate()
      : undefined,
    approvedByDirectorTime: data?.approvedByDirectorTime ? data.approvedByDirectorTime.toDate() : undefined,
  } as SubFormModel;
};

export const submitSubForm = async (
  eventForm: EventFormModel,
  subForm: SubFormModel,
  type: SubFormType
): Promise<SubFormStatus> => {
  if (
    type === SubFormType.REGISTRATION &&
    (subForm as RegistrationSubFormModel).dedicatedPageOption === false &&
    (subForm as RegistrationSubFormModel).onlineProductOption === false
  ) {
    (eventForm[`${type}Status`] as SubFormStatus) = SubFormStatus.APPROVED;
    await updateSubFormStatus(eventForm.id, SubFormStatus.APPROVED, type);
    return SubFormStatus.APPROVED;
  } else {
    (eventForm[`${type}Status`] as SubFormStatus) = SubFormStatus.SUBMITTED;
    const emailAndDepartment = emailAndDeparmentFromSubFormType(type);
    await updateSubFormStatus(eventForm.id, SubFormStatus.SUBMITTED, type);
    sendEventFormEmail(
      emailAndDepartment[0],
      `A ${emailAndDepartment[1]} form has been submitted.`,
      "Please review and approve/request for change.",
      eventForm
    );
    return SubFormStatus.SUBMITTED;
  }
};

export const approveSubForm = async (eventForm: EventFormModel, type: SubFormType) => {
  // TODO: send sendEventFormEmail to submittee to notify them that the type form has been approved
  if (eventFormApproved(eventForm)) {
    sendEventFormEmail(
      eventForm.submittee.email,
      "Your Event Form has been fully approved!",
      "You can now proceed with your event",
      eventForm
    );
  }
  await updateSubFormStatus(eventForm.id, SubFormStatus.APPROVED, type);
};

export const requestForChangeSubForm = async (
  eventForm: EventFormModel,
  requestForChangeReason: string,
  type: SubFormType,
  budgetManagerOrDirector?: keyof FinanceSubFormModel
) => {
  // TODO: send email to submittee with reason on the message - ... has request for change ...
  const emailAndDepartment = emailAndDeparmentFromSubFormType(type);
  sendEventFormEmail(
    eventForm.submittee.email,
    `The reviewer has requested changes to your ${emailAndDepartment[1]} form.`,
    `Please review and make changes accordingly.\n${requestForChangeReason}`,
    eventForm
  );
  await updateDoc(
    doc(db, EVENT_FORM, EVENT_FORM, currentStaffYear.toString(), eventForm.id, "subForms", type),
    budgetManagerOrDirector
      ? {
          requestForChangeReason,
          [budgetManagerOrDirector]: SubFormStatus.REQUEST_FOR_CHANGE,
        }
      : {
          requestForChangeReason,
          status: SubFormStatus.REQUEST_FOR_CHANGE,
        }
  );
};

const updateSubFormStatus = async (id: string, status: SubFormStatus, type: SubFormType) =>
  await updateDoc(doc(db, EVENT_FORM, EVENT_FORM, currentStaffYear.toString(), id, "subForms", type), {
    status,
  });

export const updateSubForm = async (id: string, form: SubFormModel, type: SubFormType) =>
  await updateDoc(doc(db, EVENT_FORM, EVENT_FORM, currentStaffYear.toString(), id, "subForms", type), {
    ...form,
  });

export const deleteSubFormData = async (id: string, type: SubFormType) =>
  await setDoc(doc(db, EVENT_FORM, EVENT_FORM, currentStaffYear.toString(), id, "subForms", type), {});

export const deleteEventForm = async (id: string) => {
  for (const type in SubFormType) {
    await deleteDoc(doc(db, EVENT_FORM, EVENT_FORM, currentStaffYear.toString(), id, "subForms", type.toLowerCase()));
  }
  await deleteDoc(doc(db, EVENT_FORM, EVENT_FORM, currentStaffYear.toString(), id));
};

const emailAndDeparmentFromSubFormType = (type: SubFormType) => {
  switch (type) {
    case SubFormType.MARKETING:
      return [MARKETING_EMAIL, "Marketing"];
    case SubFormType.FINANCE:
      return [FINANCE_EMAIL, "Finance"];
    // case SubFormType.RISK:
    //   return [RISK_EMAIL, "Risk"];
    case SubFormType.REGISTRATION:
      return [REGISTRATION_EMAIL, "Registration"];
  }
};
