export const allowedEmails = [
  "michelle.chen@sowaustralia.com",
  "kayla.tiga@sowaustralia.com",
  "justin.hung@sowaustralia.com",
  "ashley.kim@sowaustralia.com",
  "kiran.tam@sowaustralia.com",
  "sabrina.hu@sowaustralia.com",
  "josephine.ng@sowaustralia.com",
  "jenny.tien@sowaustralia.com",
  "luca.moon@sowaustralia.com",
  "daniel.kim@sowaustralia.com",
  "ian.chung@sowaustralia.com",
  "dominic.tjong@sowaustralia.com",
  "daniel.lee@sowaustralia.com",
  "justin.jiang@sowaustralia.com",
  "nathania.adikarta@sowaustralia.com",
  "emily.lee@sowaustralia.com",
  "kimchankwon@gmail.com",
  "saya.hiidome@sowaustralia.com",
  "april.jiang@sowaustralia.com",
  "daniel.teo@sowaustralia.com",
  "faith.teo@sowaustralia.com",
  "dave.choi@sowaustralia.com",
];
